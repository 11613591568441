import localeAntd from 'antd/es/locale/es_ES'
import moment from 'moment'
import Arr from '../helpers/Arr'
import fichapFormMessages from './es-ES/fichapForm'

let messages = {
  restore: 'Restaurar',
  BRANCH_NOT_EXISTS: 'La sucursal no existe',
  TYPE_OF_DOCUMENT_NOT_VALID: 'Tipo de documento no válido',
  DOCUMENT_NOT_VALID: 'Documento no válido',
  FIRSTNAME_NOT_VALID: 'Primer nombre no válido',
  FIRST_LASTNAME_NOT_VALID: 'Apellido no válido',
  MAIN_BRANCH_IS_REQUIRED: 'Sucursal principal es requerida',
  REPITED_NAME_AND_SURNAME: 'Nombre y apellido repetido',
  NOT_VALID_EMAIL: 'Email no válido',
  REPITED_EMAIL: 'Email repetido',
  Note: 'Nota',
  Dashboard: 'Dashboard',
  Team: 'Equipo',
  TeamMembers: 'Miembros',
  Collaborators: 'Colaboradores',
  collaborators: 'colaboradores',
  absencesDashboard: 'Tablero de ausencias',
  Shift: 'Turno',
  Shifts: 'Turnos',
  shifts: 'turnos',
  dayoffs: 'Licencias',
  document: 'Documento',
  news: 'Novedades',
  'workdays.review': 'Revisión de marcas',
  'users.offboarded': 'Listado de bajas',
  'dayoffs.manage': 'Aprobación de licencias',
  'dayoffs.config': 'Motivos de licencias',
  'vacation.matrices': 'Matrices vacacionales',
  workdays: 'Jornadas',
  'company.profile': 'Datos de la empresa',
  branches: 'Sucursales',
  structures: 'Jerarquías',
  specialdays: 'Días especiales',
  company: 'Configuración',
  organizationChart: 'Organigrama',
  OrganizationChart: 'Organigrama',
  salary: 'Sueldos',
  comunication: 'Comunicación',
  customFields: 'Datos personalizables',
  self: '',
  tools: '',
  EarlyArrival: 'Llegada temprana',
  EarlyLeave: 'Salida temprana',
  NightTime: 'Nocturnas',
  shift: 'turno',
  SettlementDay: 'Fecha de liquidación',
  WorkshiftReport: 'Reporte de turnos',
  TotalHours: 'Horas totales',
  AssignTemplate: 'Asignar plantilla',
  SettlementDeleted: 'Liquidación eliminada con éxito',
  SettlementDeleteError: 'Error al eliminar liquidación',
  AssignSettlement: 'Asignar recibo',
  Page: 'Página',
  SelectABranch: 'Seleccione una sucursal',
  SelectTemplate: 'Seleccionar una plantilla',
  SelectShiftTemplateFromTo: 'Seleccione desde qué fecha desea agregar la plantilla (opcional)',
  Heatmap: 'Mapa de calor',
  heatmap: 'Mapa de calor',
  NoTINFound: 'No hay coincidencia, verifique los datos',
  earlyHours: 'Horas de llegada temprana',
  Timeline: 'Línea de tiempo',
  Organigram: 'Organigrama',
  LOW: 'Baja',
  BulKLoadFile: 'carga-masiva-fichap',
  organigram: 'organigrama',
  NoMatches: 'Sin coincidencias',
  Matches: 'Coincidencias',
  BulkLoad: 'Carga masiva',
  SelectFreeDays: 'Seleccione los días francos',
  organization_chart_title_card1_3: '',
  organization_chart_title_card1_1: '',
  organization_chart_title_card1_2: '',
  organization_chart_title_card1_4: '',
  Back: 'Anterior',
  GoBack: 'Volver',
  BREAK: 'DESCANSO',
  BranchOfficesColaborator: 'Sucursal del colaborador',
  BranchOfficesAllowed: 'Sucursales permitidas',
  EditFichapUser: 'Editar usuario Fichap',
  AdressStreetAndNumber: 'Dirección (calle y número)',
  Department: 'Departamento',
  Next: 'Siguiente',
  AdditionalIndicators: 'Indicadores adicionales',
  PostalCode: 'Código postal',
  FichapUsers: 'Usuarios Fichap',
  NewUser: 'Nuevo usuario',
  EditUser: 'Editar usuario',
  User: 'Usuario',
  Users: 'Usuarios',
  Rol: 'Rol',
  Location: 'Localidad',
  NewFichapUser: 'Nuevo usuario Fichap',
  Configuration: 'Configuración',
  Day: 'Día',
  EarlyClockOut: 'Salida temprana',
  DaysAndHours: 'Días y horarios',
  RememberTheGreenDays:
    'Recuerde que los días laborales en verde también pueden ser seleccionados como francos.',
  AssignScheduleFreeDay: 'Asigne un horario para cada franco',
  _Document: 'Documento',
  ShiftTemplateAssigned: 'Plantilla de turno asignada',
  IsACollab: 'Es un colaborador',
  NumberOfRequest: 'N° de solicitud',
  CasualtyList: 'Listado de bajas',
  SpecificWorkshift: 'Jornada específica',
  Issue: 'Asunto',
  EndAssignment: 'Finalizar asignación',
  Divorced: 'Divorciado/a',
  CustomWorkshift: 'Jornada personalizada',
  ASuperiorBranchWasRemoved: 'Se eliminó una Sucursal superior',
  SelectASuperiorBranch: 'Seleccione una nueva "Sucursal superior" para dichas sucursales',
  DoYouWishToTerminateTheService:
    '¿Deseas dar de baja permanente a los colaboradores seleccionados? Recuerda que esta acción no se puede revertir.',
  TheDataInTheFolderWillBeLost: 'Se perderán los datos de la carpeta.',
  DoYouWantToDeleteItAnyway: '¿Deseas eliminarla de todas maneras?',
  DoYouWishToTerminateEmployee: '¿Deseas dar de baja permanentemente a dicho colaborador?',
  workshift: 'Jornada Diaria',
  SelectDataToDisplay: 'Seleccione los datos que desea visualizar',
  'workshift-this-week': 'Jornada Diaria',
  'shift-this-week': 'Jornada Diaria',
  EmployeesDeletedSuccess: 'Colaboradores eliminados con éxito',
  ArticleCreated: 'Artículo creado con éxito',
  ArticleDeleted: 'Artículo eliminado con éxito',
  ArticleUpdated: 'Artículo actualizado con éxito',
  OrganizationalMap: 'Mapa organizacional',
  casualtylist: 'Listado de bajas',
  EmployeesReport: 'Informe de colaboradores',
  EditShift: 'Editar turno',
  Hirearchies: 'Jerarquías',
  HirearchiesMap: 'Mapa de jerarquías',
  InsertHierarchy: 'Crear jerarquía',
  TypeOfHierarchies: 'Tipos de jerarquías',
  StartTimeMustBeBefore: 'La hora de inicio debe ser anterior a la hora de fin',
  StartDateMustBeBefore: 'La fecha de inicio debe ser anterior a la fecha de fin',
  AddHirearchy: 'Añadir jerarquía',
  Pendient: 'Pendiente',
  AddColumn: 'Agregar columna',
  EditColumn: 'Editar columnas',
  MOTOR: 'Discapacidad motora',
  SENSORY: 'Discapacidad sensorial',
  INTELLECTUAL: 'Discapacidad intelectual',
  MENTAL: 'Discapacidad psíquica',
  MULTIPLE: 'Discapacidad múltiple',
  AssignShifts: 'Agregar turno',
  MassiveAssign: 'Asignación masiva',
  ValidUser: 'Nombre de usuario válido',
  'rol-user': 'Colaborador',
  'rol-administrator': 'Admin',
  'rol-owner': 'Owner',
  'rol-fichap': 'Fichap',
  'rol-manager': 'Manager',
  'rol-viewer': 'Viewer',
  'was not recognized in this image, do you want to change it anyways?':
    'no fué reconocido en esta imagen, ¿quieres actualizarla igualmente?',
  'Error in massive asign': 'Error en asignación masiva',
  DeleteShift: 'Eliminar turno',
  Workday: 'Jornada',
  Checking: 'Validando',
  UserAlreadyExists: 'Nombre de usuario en uso',
  CustomizeJourney: 'Personalizar jornada',
  Journeys: 'Jornadas',
  textHere: 'Texto aquí',
  UpdateHierarchy: 'Editar jerarquía',
  RestDay: 'Franco',
  RestDays: 'Francos',
  AddRestDays: 'Agregar francos',
  Break: 'Descanso',
  NewManualWorkday: 'Nueva jornada manual',
  'Download journeys': 'Descargar jornadas',
  OVERLAPS: 'La jornada se superpone con otra ya existente',
  MAX_HOURS: 'La jornada excede las horas máximas permitidas',
  BREAK_OVERLAPS: 'El descanso se superpone con otro ya existente',
  REPITED: 'Jornada repetida',
  CLOCKIN_DATE_NOT_VALID: 'Inicio de jornada inválido',
  CLOCKOUT_DATE_NOT_VALID: 'Fin de jornada inválido',
  BREAK_START_DATE_NOT_VALID: 'Inicio de descanso inválido',
  BREAK_END_DATE_NOT_VALID: 'Fin de descanso inválido',
  REPITED_BREAK: 'Descanso repetido',
  BREAK_OUTSIDE_CLOCK: 'Descanso fuera de jornada',
  CLOCK_END_BEFORE_START: 'Fin de jornada anterior a inicio de jornada',
  BREAK_END_BEFORE_START: 'Fin de descanso anterior a inicio de descanso',
  BREAK_START_END_EQUAL: 'El inicio y fin del descanso no puede ser igual',
  CLOCK_START_END_EQUAL: 'El inicio y fin de la jornada no puede ser igual',
  ManualWorkdayError: 'Error al crear jornada manual',
  Dates: 'Fechas',
  ManualWorkdaySuccess: 'Jornada manual creada con éxito',
  manualWorkday: 'Jornada manual',
  Create: 'Crear',
  Collaborator: 'Colaborador',
  addBreak: 'Agregar descanso',
  SuperiorHirearchy: 'Jerarquía superior',
  ShiftTemplates: 'Gestión de turnos',
  Templates: 'Plantillas',
  dayoffId: 'ID Ausencia',
  RequestRejected: 'Solicitud rechazada con éxito',
  RequestApproved: 'Solicitud aprobada con éxito',
  ApprovedRequestError: 'Error al aprobar solicitud',
  RejectRequestError: 'Error al rechazar solicitud',
  Hirearchy: 'Jerarquía',
  People: 'Personas',
  loginError: 'Error al iniciar sesión, el usuario o la contraseña son incorrectos',
  USER_NOT_FOUND: 'El usuario no existe en la base de datos o se encuentra deshabilitado.',
  COMPANY_NOT_FOUND: 'La compañía no existe en la base de datos o se encuentra deshabilitada.',
  BRANCHOFFICE_NOT_FOUND: 'La sucursal no existe en la base de datos o se encuentra deshabilitada.',
  LOGIN_NOT_ALLOWED: 'No tiene permisos para hacer login en este terminal.',
  Main: 'Principal',
  Requests: 'Solicitudes',
  requests: 'solicitudes',
  Profile: 'Perfil',
  Reports: 'Informes',
  ShiftsReport: 'Reporte de jornadas',
  Help: 'Ayuda',
  Branch: 'Sucursal',
  BranchOffices: 'Sucursales',
  BranchID: 'ID de Fichap Point',
  FichapPointID: 'ID de Fichap Point',
  IllnesOfFamily: 'Enfermedad de un familiar',
  ManualWorkdayCreated: 'Jornada manual creada con éxito',
  BranchPicture: 'Foto',
  SuperiorOrganization: 'Organización superior',
  TimeZone: 'Zona horaria',
  Progress: 'Progreso',
  Manage: 'Gestionar',
  SpecialDays: 'Días especiales',
  Average: 'Promedio',
  Absenteeism: 'Ausentismo',
  CreatePosition: 'Crear puesto',
  PositionTypes: 'Tipos de puestos',
  Presences: 'Presencias',
  Presenteeism: 'Presentismo',
  AbsenteeismRate: 'Tasa de ausentismo',
  CurrentAbsenteeismRate: 'Tasa de ausentismo actual',
  AbsenteeismType: 'Tipos de ausencias',
  Settings: 'Configuración',
  DetailedAbsence: 'Ausencia detallada',
  Absences: 'Licencias',
  absences: 'licencias',
  LABORAL_DAYS: 'Laborales',
  CALENDAR_DAYS: 'Calendario',
  DAYS_GENERATE_AT_SAME_TIME: 'Los días se generan todos a la vez',
  DAYS_GENERATE_ALONG_THE_YEAR_DAILY: 'Los días se generan a lo largo del año, diariamente',
  '1_MONTH_AFTER_CYCLE': '1 mes después del final de ciclo',
  '2_MONTH_AFTER_CYCLE': '2 meses después del final de ciclo',
  '3_MONTH_AFTER_CYCLE': '3 meses después del final de ciclo',
  '4_MONTH_AFTER_CYCLE': '4 meses después del final de ciclo',
  '5_MONTH_AFTER_CYCLE': '5 meses después del final de ciclo',
  '6_MONTH_AFTER_CYCLE': '6 meses después del final de ciclo',
  '7_MONTH_AFTER_CYCLE': '7 meses después del final de ciclo',
  '8_MONTH_AFTER_CYCLE': '8 meses después del final de ciclo',
  '9_MONTH_AFTER_CYCLE': '9 meses después del final de ciclo',
  '10_MONTH_AFTER_CYCLE': '10 meses después del final de ciclo',
  '11_MONTH_AFTER_CYCLE': '11 meses después del final de ciclo',
  AFTER_1_CYCLE: '1 ciclo después',
  AFTER_2_CYCLE: '2 ciclos después',
  AFTER_3_CYCLE: '3 ciclos después',
  AFTER_4_CYCLE: '4 ciclos después',
  AFTER_5_CYCLE: '5 ciclos después',
  NEVER: 'Nunca vence',
  DAYS_GENERATE_ALONG_THE_YEAR_MONTHLY: 'Los días se generan a lo largo del año, mensualmente',
  SpecialDaysNotWorked: 'Días especiales no trabajados',
  CheckIn: 'Check in',
  AssignShift: 'Asignar turno',
  CheckOut: 'Check out',
  SelectAShift: 'Seleccione un turno',
  RestCheckIn: 'Check in descanso',
  GeneralBreak: 'Descanso general',
  CreateShift: 'Crear turno',
  CreateSimpleTemplate: 'Crear plantilla simple',
  CreateCustomTemplate: 'Crear plantilla personalizada',
  SelectExistingShift: 'Seleccionar plantilla de turnos',
  AssignForThisWeek: 'Asignar turno para esta semana',
  AssignForEveryWeek: 'Asignar turno para todas las semanas',
  CustomBreak: 'Descanso personalizado',
  AssignedPeriod: 'Período asignado',
  SearchByTitleOrId: 'Buscar por título o ID',
  ApplyDifferentBreak: 'Aplique un descanso diferente al descanso general',
  RestCheckOut: 'Check out descanso',
  CheckIn1: 'Check in 1',
  CheckOut1: 'Check out 1',
  CheckIn2: 'Check in 2',
  CheckOut2: 'Check out 2',
  CheckIn3: 'Check in 3',
  CheckOut3: 'Check out 3',
  CheckIn4: 'Check in 4',
  CheckOut4: 'Check out 4',
  CheckIn5: 'Check in 5',
  CheckOut5: 'Check out 5',
  RestCheckIn1: 'Check in descanso 1',
  RestCheckOut1: 'Check out descanso 1',
  RestCheckIn2: 'Check in descanso 2',
  RestCheckOut2: 'Check out descanso 2',
  RestCheckIn3: 'Check in descanso 3',
  RestCheckOut3: 'Check out descanso 3',
  RestCheckIn4: 'Check in descanso 4',
  RestCheckOut4: 'Check out descanso 4',
  RestCheckIn5: 'Check in descanso 5',
  RestCheckOut5: 'Check out descanso 5',
  WorkingDayDuration: 'Duración de jornada',
  RestDurationExceed: 'Horas extra descanso',
  LateEntryTime: 'Tiempo de llegada tarde',
  EarlyHours: 'Tiempo de entrada temprano',
  RegularHours: 'Horas normales',
  SpecialDayHours: 'Horas trabajadas en feriado',
  RestDayWorkedHours: 'Horas en día de descanso',
  RestDuration: 'Duración descanso',
  NightHours: 'Horas nocturnas',
  NightExtraHours: 'Horas extras nocturnas',
  DistanceTraveled: 'Distancia recorrida',
  Subscription: 'Suscripción',
  year: 'Anual',
  month: 'Mensual',
  Billing: 'Facturación',
  Companies: 'Organización',
  Admin: 'Administración',
  AdminRoles: 'Roles de administrador',
  Managers: 'Administradores',
  team: 'Equipo',
  add: 'Agregar',
  import: 'Importar',
  showingResultsShowing: 'Mostrando registros del ',
  showingResultsTo: 'al ',
  showingResultsOf: 'de un total de ',
  showingResultsRecords: 'registros',
  Image: 'Imagen',
  Lastname: 'Apellido',
  FirstLastname: 'Primer apellido',
  SecondLastname: 'Segundo apellido',
  MotherLastname: 'Apellido de la madre',
  ProfilePicture: 'Foto de perfil',
  DocumentExpirationDate: 'Vencimiento de documento',
  Sickness: 'Enfermedad',
  New: 'Nueva',
  CreateNotification: 'Crear notificación',
  NotPermission: 'No tienes permisos para realizar esta acción',
  PasswordNotValid:
    'La contraseña debe tener al menos 8 digitos, una mayúscula, una minúscula y un número',
  NoSupportingAttachment: 'No tiene adjunto justificatorio',
  totalNormalHours: 'Total de horas normales',
  Send: 'Enviar',
  Unavailability: 'No disponibilidad',
  Avaliability: 'Disponibilidad',
  AddAbsence: 'Añadir ausencia',
  Name: 'Nombre',
  Attach: 'Adjuntar',
  CreateRequest: 'Crear solicitud',
  FirstName: 'Primer nombre',
  SecondName: 'Segundo nombre',
  LegacyNumber: 'N° de legajo',
  File: 'Legajo',
  IdentificationCard: 'Cédula',
  IDAssignedShift: 'ID y turno asignado',
  Actions: 'Acciones',
  DateOfBirth: 'Fecha de Nacimiento',
  dateOfBirth: 'Fecha de Nacimiento',
  Address: 'Dirección',
  City: 'Ciudad',
  City2: 'Ciudad secundaria',
  State: 'Estado',
  LocationState: 'Provincia',
  LocationState2: 'Provincia secundaria',
  disability: 'Discapacidad',
  Employeestate: 'Estado',
  ZipCode: 'Código Postal',
  ZipCode2: 'Código Postal secundario',
  Gender: 'Género',
  Secondary: 'Secundario',
  Female: 'Femenino',
  'Cancel subscription': 'Cancelar suscripción',
  'Update plan': 'Actualizar plan',
  Male: 'Masculino',
  Other: 'Otro',
  Married: 'Casado/a',
  Concubine: 'Concubino/a',
  Single: 'Soltero/a',
  Widower: 'Viudo/a',
  Phone: 'Teléfono',
  Document: 'N° de Documento',
  workshiftTheoric: 'Turno teorico',
  DateOfCreation: 'Fecha de creación',
  Email: 'Email',
  ExeededTheMaximumDaysAllowedForReason:
    'Ya superaste los días máximos permitidos para dicho motivo de solicitud',
  WorkEmail: 'Email laboral',
  SelectedDateCannotBeEarlier: 'La fecha seleccionada no puede ser anterior a la fecha actual',
  SelectedDateCannotBeTheSame: 'Las fechas seleccionadas no pueden ser las mismas',
  AreYouAdministrator: '¿Eres administrador?',
  Password: 'Contraseña',
  NewPassword: 'Nueva contraseña',
  RepeatPassword: 'Repita su nueva contraseña',
  EditPassword: 'Editar contraseña',
  TypeDocument: 'Tipo de Documento',
  workedAndShiftsHours: 'Jornadas y horas trabajadas',
  Nationality: 'Nacionalidad',
  CountryOfBirth: 'País de Nacimiento',
  WorkingCountry: 'País de trabajo',
  CityOfBirth: 'Ciudad de Nacimiento',
  CivilStatus: 'Estado Civil',
  AbsenceStatus: 'Estado de solicitud',
  NoData: 'No hay datos',
  generationZ: 'Generación Z',
  generationY: 'Generación Y',
  generationX: 'Generación X',
  BabyBoom: 'Baby Boomers',
  'Clean filter': 'Limpiar filtros',
  Filters: 'Filtros',
  StudyLevel: 'Nivel de estudio',
  NOT_VALID_DISABILITY: 'Discapacidad no válida',
  NOT_VALID_EXPIRATION_DATE: 'Fecha de vencimiento no válida',
  Nick: 'Nick',
  PersonalData: 'Datos personales',
  allFields: 'Totalidad',
  contactData: 'Contacto',
  contractData: 'Contrato',
  labourData: 'Laboral',
  personalData: 'Personal',
  familyData: 'Familia',
  organizationData: 'Organización',
  Personal: 'Personal',
  Street: 'Calle',
  StreetNumber: 'Número',
  Floor: 'Piso',
  Floor2: 'Piso secundario',
  Save: 'Guardar',
  Summary: 'Resumen',
  FileNumber: 'N° de Legajo',
  Category: 'Categoría',
  QuoteGroup: 'Grupo de Cotización',
  DateOfAdmission: 'Fecha de ingreso',
  BegginDate: 'Fecha de inicio',
  SSN: 'N° de Seguridad Social',
  SocialSecurityNumber: 'Número de Seguridad Social',
  Position: 'Puesto / Cargo',
  NameShift: 'Titulo de los turnos',
  POSITION: 'Posición / Puesto / Cargo',
  Event: 'Evento',
  Role: 'Puesto / Cargo',
  position: 'Puesto / Cargo',
  LaborUnion: 'Sindicato',
  Posicion: 'Puesto / Cargo',
  'Puesto / Cargo': 'Puesto / Cargo',
  Posição: 'Puesto / Cargo',
  Bank: 'Banco',
  CBU: 'CBU',
  Alias: 'Alias',
  MainBranch: 'Sucursal principal',
  EndEmploymentContract: 'Fin de contrato laboral',
  InternalPhone: 'Teléfono Interno',
  YearsWorked: 'Antigüedad',
  less1year: 'Menos de 1 año',
  _1to5years: '1 a 5 años',
  _6to10years: '6 a 10 años',
  _11to20years: '11 a 20 años',
  _20years: '20+',
  EndTrialPeriod: 'Fin período de prueba',
  ReportsTo: 'Reporta a',
  WorkData: 'Datos laborales',
  BillingInformation: 'Datos de liquidación',
  EmergencyContact: 'Contacto de emergencia',
  Link: 'Vínculo',
  Hourly: 'Cada hora',
  DaysWorked: 'Días trabajados',
  'Total workdays': 'Jornadas totales',
  daysWorked: 'Jornadas trabajadas',
  SpecialWorkedDays: 'Días especiales trabajados',
  ApprovedAbsences: 'Ausencias aprobadas',
  notApprovedAbsences: 'Ausencias no aprobadas',
  LateArrivalTime: 'Tiempo en llegadas tarde',
  HoursWorked: 'Horas trabajadas',
  HoursLate: 'Horas tarde',
  DailyShifts: 'Jornadas',
  Daily: 'Jornadas',
  Monthly: 'Mensual',
  Year: 'Año',
  Annual: 'Anual',
  Biannual: 'Semestral',
  Weekly: 'Semanal',
  Quarterly: 'Trimestral',
  AccountNumber: 'N° de cuenta',
  typeOfHierarchies: 'Tipos de jerarquías',
  ForHours: 'Por horas',
  CompleteDay: 'Día completo',
  COMPLETE: 'Todos los días juntos',
  SPLITED: 'En partes',
  TypeOfRemuneration: 'Tipo de Remuneración',
  PaymentFrequency: 'Frecuencia de pago',
  SelectOne: 'Seleccionar',
  // AddDocument: 'Agregar archivo',
  NotValidFile: 'Archivo no válido',
  Work: 'Laboral',
  Documents: 'Documentos',
  TypeOfDayoff: 'Tipo de ausencia',
  AddDocuments: 'Añadir documento',
  GeneralDocumentsChart: 'Gráfico archivos generales',
  TaxIdentification: 'Código de identificación fiscal',
  MedicalCoverage: 'Cobertura médica',
  WORKDAY_TOO_LONG: 'Jornada demasiado larga',
  DepartureDate: 'Fecha de finalización',
  AdvancedFilters: 'Filtros avanzados',
  Age: 'Edad',
  Revisions: 'Revisiones',
  'Brand reviews': 'Revisiones de marcas',
  UnionAgreement: 'Sindicato acordado',
  ContactData: 'Datos de contacto',
  EmployeesData: 'Datos de colaboradores',
  MaternityPaternityLeave: 'Ausencia por maternidad/paternidad',
  LoadedBy: 'Subido por',
  MaternityPaternity: 'Maternidad/Paternidad',
  UploadDate: 'Fecha de carga',
  SickLeave: 'Ausencia por enfermedad',
  UnexcusedAbsence: 'Ausencia sin justificar',
  'Dayoff without advice': 'Ausencia sin aviso',
  DoesItAllowPartTime: '¿Habilita jornada parcial?',
  VacationAbsence: 'Ausencia por Vacaciones',
  StudyAbsence: 'Ausencia por estudio',
  Others: 'Otras',
  Status: 'Estado',
  Approved: 'Aprobadas',
  APPROVED: 'Aprobado',
  AbsenceStartEnd: 'Fecha de licencia (Inicio-Fín)',
  Rejected: ' Rechazadas',
  REJECTED: ' Rechazado',
  inactive: 'INACTIVO',
  BulkloadNews: 'Carga masiva de novedades',
  PrePayrollReport: 'Informe de pre-nómina',
  'Download timeline': 'Descargar linea del tiempo',
  PENDING: 'Pendiente',
  Observation: 'Observación',
  active: 'ACTIVO',
  SinceLastMonth: 'desde el mes anterior',
  SinceLastWeek: 'desde la semana anterior',
  DayOfTheWeek: 'Día de la semana',
  LateEntry: 'Llegadas tarde',
  MoreThanAllowed: 'Mayor al permitido',
  MoreThan: 'Descanso mayor al',
  Allowed: 'permitido',
  Title: 'Título',
  Description: 'Descripción',
  Folder: 'Carpeta',
  Signs: 'Firmas',
  Cancel: 'Cancelar',
  NewFile: 'Nuevo documento',
  EditFile: 'Editar documento',
  GeneralFiles: 'Archivos generales',
  AddDocument: 'Añadir documento',
  EmptyAdvice: 'Todavía no tienes documentos en esta carpeta',
  MassiveUpload: 'Carga masiva de datos',
  ImageImport: 'Carga de imagenes',
  SelectColumn: 'Elegir columna',
  IgnoreColumn: 'Ignorar esta columna',
  Accept: 'Aceptar',
  Data: 'Datos',
  Pictures: 'Fotos',
  SocialInsurance: 'Seguridad social',
  Delete: 'Eliminar',
  Confirm: 'Confirmar',
  Mark: 'Ficha / Marca',
  Upload: 'Subir',
  SelectAll: 'Seleccionar todo',
  AreYouSure: '¿Seguro desea eliminar?',
  AreYouSureAssignment: '¿Seguro desea finalizar asignación?',
  ApprovedPics: 'Aprobadas',
  DissaprovedPics: 'Desaprobadas',
  Start: 'Inicia',
  StartDate: 'Fecha inicio',
  startDate: 'Fecha de inicio',
  MANTAINANCE: 'En reparación',
  CreateAndAssignArticle: 'Crear y asignar artículo',
  ItemDescription: 'Descripción del artículo',
  ArticleAssigned: 'Artículo asignado con éxito',
  ItemIssue: 'Asunto de la asignación',
  EndDate: 'Fecha fin',
  ArticleUnassigned: 'Asignación finalizada con éxito',
  endDate: 'Fecha de fin',
  End: 'Termina',
  Date: 'Fecha',
  SeeUpdates: 'Ver edición',
  LastUpdate: 'Última edición',
  OriginalTime: 'Horario original',
  Modified: 'Modificado',
  PunchEdition: 'Edición de marca',
  Modify: 'Modificar',
  DeleteTodayShifts: `Se eliminarán los turnos de hoy ${moment().format(
    'DD-MM-YYYY',
  )} ¿Deseas confirmar dicha acción?`,
  DeleteWeekShifts: `Se eliminaran los turnos de la semana a actual del ${moment()
    .startOf('week')
    .format('DD-MM-YYYY')} al ${moment()
    .endOf('week')
    .format('DD-MM-YYYY')}. ¿Deseas confirmar dicha acción?`,
  DeleteMonthShifts: `Se eliminarán los turnos del mes actual ¿Deseas confirmar dicha acción?`,
  DeleteAllShifts: `Se eliminarán todos los turnos a partir del ${moment().format(
    'DD-MM-YYYY',
  )} ¿Deseas confirmar dicha acción?`,
  DeleteSelectedShifts: `Se eliminarán los turnos seleccionados ¿Deseas confirmar dicha acción?`,
  Today: 'Hoy',
  ThisWeek: 'Esta Semana',
  All: 'Todo',
  Selected: 'Seleccionados',
  MassiveImport: 'Importación masiva',
  MASSIVE: 'Carga masiva',
  SINGLE: 'Carga individual',
  'Fichap-timeline': 'Fichap-linea-del-tiempo',
  Import: 'Importar',
  DownloadModel: 'Descargar modelo',
  DateOfEntry: 'Fecha de ingreso',
  TypeContract: 'Tipo de contrato',
  TypeLeave: 'Tipo de baja',
  HealthControl: 'Control de salud',
  HealthInsurance: 'Código de seguro de salud',
  FoodHandlingLicense: 'Carnet de alimentos',
  Contract: 'Contrato',
  ActualSituation: 'Situación actual',
  Area: 'Area',
  VacationMatrix: 'Matriz vacacional',
  VacationGroup: 'Grupo de vacaciones',
  CostCenter: 'Centro de costos',
  Sector: 'Sector',
  Organization: 'Organización',
  SecondaryOrganization: 'Organización secundaria',
  MainOrganization: 'Organización primaria',
  Union: 'Sindicato',
  AccountType: 'Tipo de cuenta',
  Coin: 'Moneda',
  Health: 'Salud',
  CompletedData: 'Datos completados',
  ContractData: 'Datos de contrato',
  EmploymentData: 'Datos laborales',
  UnionData: 'Datos sindicato',
  OrganizationData: 'Datos organización',
  BillingData: 'Datos liquidación',
  Reason: 'Motivo',
  Reasons: 'Motivos',
  reason: 'motivo',
  reasons: 'motivos',
  DiscoverFeatures: 'Descubrí todas las funciones de tu tablero',
  FichapTutorials: 'En los tutoriales de Fichap encontrarás todo lo que necesitás saber.',
  ContractShift: 'Turno por contrato',
  c_STARTED: 'Comprimiendo archivo',
  'c_COMPRESSED STARTED 1': 'Comprimiendo archivo',
  'c_COMPRESSED STARTED 2': 'Comprimiendo archivo',
  'c_COMPRESSED STARTED 3': 'Comprimiendo archivo',
  'c_COMPRESSED STARTED 4': 'Comprimiendo archivo',
  Women: 'Mujeres',
  Men: 'Hombres',
  Neutral: 'Neutro',
  SeeNow: 'Ver ahora',
  ClockBranchoffice: 'Sucursal de fichada',
  'ClockBranchoffice 1': 'Sucursal de fichada',
  'ClockBranchoffice 2': 'Sucursal de segunda fichada',
  'ClockBranchoffice 3': 'Sucursal de tercer fichada',
  'ClockBranchoffice 4': 'Sucursal de cuarta fichada',
  'ClockBranchoffice 5': 'Sucursal de quinta fichada',
  AllowCheckBranch: 'Habilitar esta sucursal para Fichap Point',
  ActiveCollaborators: 'Colaboradores activos',
  Mon: 'Lun',
  Tue: 'Mar',
  Wed: 'Mie',
  Thu: 'Jue',
  Fri: 'Vie',
  Sat: 'Sab',
  Sun: 'Dom',
  Percentages: 'Porcentajes',
  YearlyAbsenteeism: 'Ausentismo interanual',
  Confirmation: 'Confirmación',
  DoYouWishToReinstate: '¿Deseas dar de alta nuevamente a dicho colaborador?',
  DoYouWishToConfirm: '¿Deseas confirmar dicha acción?',
  YouHaveSelected: 'Has seleccionado',
  RequestsToApprove: 'solicitudes para aprobar',
  RequestsIssued: 'Solicitudes emitidas',
  RequestToApprove: 'solicitud para aprobar',
  RequestsToReject: 'solicitudes para rechazar',
  CurrentPeriodRequests: 'Total solicitudes período actual',
  LastYearRequests: 'Total solicitudes período actual año anterior',
  RequestToReject: 'solicitud para rechazar',
  NoPendingRequests: '¡Bien! No tienes solicitudes pendientes.',
  Kinship: 'Parentesco',
  IncomeTax: 'Deducción IRPF',
  CompanyUpdated: 'Los datos de la companía fueron actualizados con éxito',
  CollaboratorUpdated: 'Los datos fueron actualizados con éxito',
  From: 'Desde',
  Kilometers: 'Kilómetros',
  To: 'Hasta',
  AddFamily: 'Agregar familiar',
  Administrator: 'Administrador',
  AreYouAdmin: '¿Es administrador?',
  HealthData: 'Datos Salud',
  AdministratorData: 'Datos Adminstrador',
  NewInventory: 'Nuevo inventario',
  AssignInventory: 'Asignar inventario',
  EditAssignment: 'Editar asignación',
  Assign: 'Asignar',
  FreeDay: 'Franco',
  HealthCareSystem: 'Sistema de salud',
  START: 'INICIA',
  END: 'TERMINA',
  UpdateTemplate: 'Editar plantilla',
  CreateTemplate: 'Crear plantilla',
  ShiftType: 'Tipo de jornada',
  SimpleShift: 'Jornada simple',
  TheCollaboratorHasNoTemplates: 'El colaborador no tiene plantillas asignadas',
  Custom: 'Personalizada',
  UpdateDataManually: 'Confirmación de datos',
  bulkLoadJourneyDescription:
    'Los campos marcados en rojo corresponden a fechas u horarios que ya tienen jornadas registradas. Revise los datos cargados, edite o elimine lo que desee y confirme la carga.',
  'document not exists': 'El documento no existe',
  ReviewErrors: 'Revise los errores encontrados, editelos y confirme la acción.',
  ChangesWillBeLost: 'Se perderán los cambios realizados.',
  DoYouWishToContinue: '¿Deseas continuar?',
  Continue: 'Continuar',
  CustomShift: 'Jornada personalizada',
  SelectWorkdays: 'Seleccione los días laborables',
  PositionUpdated: 'Puesto actualizado',
  username: 'Nombre de usuario',
  'Position/charge': 'Puesto/Cargo',
  'Invalid characters': 'Haz ingresado carácteres inválidos',
  MedicalCoverageChosen: 'Seguro médico elegido',
  CreateInventory: 'Crear inventario',
  DailyWorkingHours: 'Jornadas diarias de todos los colaboradores',
  UpdatePosition: 'Actualizar puesto',
  VacationSchedule: 'La gestión de ausencias minuto a minuto.',
  Pending: 'Pendientes',
  Nomine: 'Nómina',
  Positions: 'Puestos',
  CompleteDataEmployees: 'Conocé la demografía de tu empresa.',
  DemographicData: 'Datos demográficos',
  DemographicDataSubtitle: 'Ubicación, género, edad, estado civil, nacionalidad.',
  WorkAccidents: 'Accidentes laborales',
  ACCIDENT: 'Accidente',
  EvolutionSixMonths: 'Evolución en los últimos 6 meses',
  ErrorReporting: 'Reporte de errores',
  CustomReportFiltering: 'Informe personalizado',
  DetailedShiftReport: 'Informe detallado de jornadas',
  GeneralShiftReport: 'Informe de jornadas generales',
  WorkedShifts: 'Jornadas trabajadas',
  ChooseReport: 'Elegí tus parametros y configura tu informe',
  OutOfAgreement: 'Fuera de convenio',
  Soon: 'Próximamente',
  JourneyMassiveAdd: 'Carga masiva de jornadas',
  Duration: 'Duración',
  ComingSoon: 'Próximamente...',
  InAgreement: 'En convenio',
  HistoryOfReports: 'Historial de los informes solicitados',
  WouldLikeReport: '¿Deseas un informe más personalizado?',
  FichapAllowsYou: 'Fichap te permite crear informes únicos según las necesidades de tu empresa.',
  ContactUs: '¡Contáctate con nosotros!',
  LearnMore: 'Saber más',
  CustomReport: 'Informe personalizado',
  EditInventory: 'Editar Inventario',
  DeselectAll: 'Deseleccionar todo',
  ApplyFilters: 'Aplicar filtros',
  PartialAbsence: 'Ausencia por horas',
  FullDayAbsence: 'Ausencia día completo',
  Apply: 'Aplicar',
  UploadImages: 'Carga de imagenes',
  Loading: 'Cargando',
  Number: 'Número',
  Number2: 'Número secundario',
  Branches: 'Sucursales',
  Hierarchies: 'Jerarquías',
  AddOrganization: 'Nueva organización',
  FatigueOr: 'Fatiga o',
  BodyDiscomfort: 'malestar corporal',
  CoughOrDifficulty: 'Tos o dificultad',
  Breathing: 'para respirar',
  HighTemperature: 'Temperatura alta',
  Over37: 'Más de 37.5°',
  NasalCongestion: 'Congestion nasal',
  OrHeadache: 'o dolor de cabeza',
  CleanHands: 'Manos limpias',
  SocialDistance: 'Distancia social',
  AlwaysMask: 'Tapabocas siempre',
  Apt: 'Apto',
  NoSymptoms: 'No hay síntomas',
  Care: 'Cuidado',
  PossibleSymptom: 'Posible síntoma',
  Birthday: 'Cumpleaños',
  DocumentSignature: ' Firma de documento',
  EmployeeAbsences: 'Solicitud de licencia',
  EmployeePayroll: 'Nuevo recibo',
  PossibleSymptomsOfCovid: 'Posibles síntomas de COVID-19',
  YouWillRecieveAMessage:
    'Si eres administrador de la empresa, recibirás un mensaje cada vez que un empleado firme electrónicamente un documento.',
  WarningTemperature: 'Temperatura de alerta',
  Symptoms: 'Síntomas',
  RequestTemperatureD: 'Solicitar temperatura al marcar',
  EnableInPoint: 'Habilitar en Point',
  EnableInMobilie: 'Habilitar en Mobile',
  GeneralData: 'Datos generales',
  FaceRecognition: 'Reconocimiento facial',
  IdentificationDocument: 'Código de identificación',
  ACTIVE: 'ACTIVO',
  View: 'Ver',
  INACTIVE: 'INACTIVO',
  // AdminRoles: 'Rol',
  // AreYouAdministrator: '¿Es administrador?',
  Validation: 'Validación de imagen',
  AllowExtraHours: 'Permitir horas extras',
  OvertimeTolerance: 'Tolerancia de horas extra (en minutos)',
  minExtraHours: 'Mínimo de horas extra',
  maxExtraHours: 'Máximo de horas extra',
  extraHoursEarlyEntryTolerance: 'Tolerancia de ingreso anticipado',
  earlyEntryAsExtraHours: 'Ingreso Anticipado como Horas Extras',
  AutomaticTolerance: 'Tolerancia de Cierre Automático con turno',
  HAPPY: 'Felicidad',
  SAD: 'Tristeza',
  CALM: 'Calmo',
  CONFUSED: 'Confundido',
  FEAR: 'Miedo',
  DISGUSTED: 'Disgustado',
  ANGRY: 'Enojo',
  SURPRISED: 'Sorpresa',
  ExtraTimeRest: 'Tiempo extra descansado',
  Breaks: 'Descansos',
  BULKLOAD_SUCCESS: 'Jornada sin error',
  'Break start': 'Inicio de descanso',
  'Break end': 'Fin de descanso',
  Temperature: 'Temperatura',
  ToleranceLateArrival: 'Tolerancia de llegadas tarde (en minutos)',
  RestTime: 'Tiempo de descanso',
  ToleranceEarlyDeparture: 'Tolerancia de salidas temprano (en minutos)',
  RangeOfHoursRest: 'Rango de horas de descanso',
  FichapMobileMap: 'Mapa Fichap Mobile',
  RestButton: 'Botón de descanso',
  EmployeePortal: 'Portal del empleado',
  RestrictLocationSignin: 'Restringir fichaje por localización',
  OrganizationModel: 'Modelo de Organización',
  InternNumber: 'Número de interno',
  workworkPhone: 'Teléfono laboral',
  QuotationGroup: 'Grupo de cotización',
  SignMark: 'Ficha / Marca',
  'footer.title': 'Fichap - Gestión inteligente de equipos',
  'footer.subtitle': ' / Todos los derechos reservados',
  'login.title': 'Ingresa con tus datos',
  'login.welcome': 'Bienvenido a Fichap',
  'login.rememberme': 'Recuerdame',
  'login.forgotpassword': '¿Olvidaste tu contraseña?',
  'login.forgotusername': '¿Olvidaste tu usuario?',
  'login.login': 'Ingresar',
  'login.accesswith': 'o ingresar con',
  'login.createaccount': '  ¿No tienes una cuenta?',
  'login.starttoday': 'Comienza hoy',
  supportchat: 'Soporte',
  'item.title': 'Titulo',
  'topBar.issuesHistory': 'Historial de Problemas',
  'topBar.projectManagement': 'Administración de Proyectos',
  'topBar.typeToSearch': 'Buscar...',
  'topBar.findPages': 'Encontrar páginas...',
  'topBar.actions': 'Acciones',
  'topBar.status': 'Estado',
  'topBar.profileMenu.hello': 'Hola',
  yearlyISTP: 'Declaración de ISTP Anual',
  'topBar.profileMenu.billingPlan': 'Plan',
  'topBar.profileMenu.role': 'Puesto',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Teléfono',
  'topBar.profileMenu.editProfile': 'Ver perfil',
  'topBar.profileMenu.logout': 'Cerrar sesión',
  Action: 'Acción',
  actionAttach: 'Adjuntar',
  actionSend: 'Enviar',
  actionHistory: 'Historial',
  actionView: 'Ver',
  actionViewProfile: 'Ver perfil',
  DateAndTimeStart: 'Fecha y hora de inicio',
  DateAndTime: 'Fecha y hora',
  startHour: 'Hora de inicio',
  endHour: 'Hora de fin',
  Disenroll: 'Dar de baja',
  UploadImage: 'Cargar imagen',
  Communication: 'Comunicación',
  NewCategory: 'Nueva categoria',
  EditCategory: 'Editar categoria',
  Comment: 'Comentario',
  cancelSubscriptionSuccess: 'Se ha cancelado la suscripción correctamente',
  CreateCategory: 'Crear categoria',
  deleteCategoryDesc: 'La categoría se eliminará definitivamente. ¿Desea eliminar de todas formas?',
  deleteVacation:
    '¿Estas seguro de eliminar los días disponibles de este colaborador? Esta acción no se puede deshacer.',
  disenrollPublicationDesc:
    'Se dará de baja la publicación y pasará a la papelera. ¿Deseas dar de baja tu publicación?',
  PublicationDesc: 'Tus publicacion pasará de estado borrador a publicadas. ¿Deseas publicarlas?',
  deletePostDesc: 'Se eliminará de forma permanente tus publicaciones ¿Deseas eliminarlas?',
  discount: '¿Descuenta?',
  Discount_: '¿Descuenta?',
  DoesItImpact: '¿Impacta en el presentismo?',
  UNIT: 'Unidades',
  PERCENTAGE: 'Porcentaje (%)',
  AMOUNT: 'Valor ($)',
  UNIQUE: 'Única vez',
  WEEKLY: 'Semanal',
  HALFMONTH: 'Quincenal',
  MONTHLY: 'Mensual',
  BIMONTHLY: 'Bimestral',
  QUARTERLY: 'Trimestral',
  BIANNUAL: 'Semestral',
  YEARLY: 'Anual',
  DAILY: 'Diario',
  YouHaveSelectedTo: 'Ha seleccionado dar de baja a',
  SelectFile: 'Seleccionar archivo',
  SelectTypeOfRequest: 'Seleccione el tipo de solicitud',
  actionDelete: 'Eliminar',
  actionCancel: 'Cancelar',
  downloadAction: 'Descargar',
  Download: 'Descargar',
  AppliedtoChartsandTables: 'Aplicado a gráficos y tablas',
  actionEdit: 'Editar',
  Filter: 'Filtrado',
  ThePasswordMustContain:
    'La contraseña debe contener al menos 8 caracteres, una letra y un número.',
  createEmployee: 'Crear colaborador',
  NightShiftRange: 'Rango de horario nocturno',
  PasswordUpdated: 'La contraseña fue actualizada con éxito',
  ExternalID: 'ID externo',
  saveEmployee: ' Guardar empleado',
  Update: 'Actualizar',
  Passport: 'Pasaporte',
  RestrictShift: 'Restringir fichaje sin turno asignado',
  invalidEmail: 'Email inválido',
  NightShiftControl: 'Permitir horas nocturnas',
  requiredField: 'Campo requerido',
  IgnoreDayoff: 'Permitir fichaje con ausencia programada',
  deleteEmployeeQuestion: '¿Seguro desea eliminar?',
  deleteEmployeeConfirmed: 'El empleado ha sido borrado exitosamente',
  SpecialDayDeleted: 'Feriado borrado exitosamente',
  deleteEmployeeError: 'Ha ocurrido un error. Intenta nuevamente',
  CreateFolder: 'Crear carpeta',
  EditFolder: 'Editar carpeta',
  FolderCreated: 'La carpeta fue creada con éxito',
  NonCustomizableFields: 'Campos predeterminados no customizables',
  FolderDeleted: 'La carpeta fue eliminada con éxito',
  FolderUpdated: 'La carpeta fue actualizada con éxito',
  ErrorCalendarUpdated: 'Ha ocurrido un error. Intenta nuevamente',
  OVERLAPPING_DATES:
    'Ha ocurrido un error. Los turnos seleccionados se superponen con otros ya existentes',
  SpecialDayUpdated: 'Feriado actualizado exitosamente',
  DefaultBreakTime: 'Tiempo de descanso general',
  employeeCreationSuccess: 'El empleado ha sido creado exitosamente',
  employeeCreationError: 'Ha ocurrido un error. Intenta nuevamente',
  SpecialDayaErrorCreate: 'Ha ocurrido un error. Intenta nuevamente',
  CompanyErrorUpdated: 'Ha ocurrido un error. Intenta nuevamente',
  UpsWeAreWorking: '¡Ups! Estamos trabajando...',
  Ups: '¡Ups!',
  ThePageYouAreLookingFor: 'La página que estas buscando está actualmente',
  WeWillBeBack: 'en mantenimiento, volveremos pronto.',
  employeeCreationEmailFail: 'El email se encuentra en uso',
  theEmailIsInUse: 'El email que ingresaste se encuentra en uso. Intenta con otro',
  theDocumentIsInUse: 'El N° de documento que ingresaste se encuentra en uso. Intenta con otro',
  OnlyLettersAndNumbersPlease: 'Ingrese solo numeros y letras',
  GeneralUpdated: 'Datos guardados con éxito',
  GeneralError: 'Ha ocurrido un error',
  ROLE_NOT_ALLOWED: 'Error, no tienes los permisos requeridos',
  CustomDataUpdated: 'Datos guardados con éxito',
  AssignDaysAndBreaks: 'Asigne días, francos y descansos',
  NotificationsUpdated: 'Notificación guardada con éxito',
  CovidUpdated: 'Datos guardados con éxito',
  ReasonUpdated: 'Motivo guardado exitosamente',
  ReasonCreated: 'Motivo creado exitosamente',
  ImagesLoaded: 'Imágenes cargadas',
  WorkdayCreated: 'Jornada creada con éxito',
  SpecialDayCreated: 'Feriado creado con éxito',
  Tips: 'Tips',
  Mi: 'Mi',
  Lu: 'Lu',
  Do: 'Do',
  Sá: 'Sá',
  Vi: 'Vi',
  Ju: 'Ju',
  Aug: 'Aug',
  // "J004": "This exception occurs when the user already exists",
  // "J004": "El ",
  WorkdayUpdated: 'Datos guardados con éxito',
  BulkLoadItemDeleted: 'Fila eliminada con éxito',
  'Collaborator created successfully': 'Colaborador creado con éxito',
  'This exception occurs when the number of document is invalid':
    'El documento ingresado es inválido',
  'This exception occurs when the email is invalid': 'El mail ingresado es inválido',
  'This exception occurs when the user already exists': 'El usuario ya existe en la base de datos',
  'This exception occurs when the email already exists': 'El mail ingresado ya está en uso',
  'This exception occurs when document already exists':
    'El número de documento ingresado ya está en uso.',
  BulkLoadSuccessFull: 'Carga masiva completada con éxito',
  BulkLoadError: 'Error al cargar datos, por favor, verifique las filas con errores',
  BulkConnectionLoadError:
    'Error al cargar los datos, por favor verifique que todos los campos sean válidos',
  ReasonDeleted: 'Motivo eliminado con éxito',
  AreYouSureToDeleteBranch: '¿Deseas eliminar dicha sucursal?',
  BranchDeleted: 'Sucursal eliminada con éxito',
  adminDeleted: 'Administrador eliminado con éxito',
  AdminCreated: 'Administrador creado con éxito',
  WorkshiftDeleted: 'Jornada eliminada con éxito',
  WorkshiftCreated: 'Jornada creada con éxito',
  BulkLoadImageDone: 'Carga masiva completada con éxito',
  WorkshiftAssigned: 'Jornadas asignadas con éxito',
  EnableAutomaticShiftGeneration: 'Habilitar generación automática de turnos',
  TheShiftCalendarWillBeUpdated:
    'El calendario de turnos se actualizará automáticamente para 8 semanas en adelante.',
  WorkshiftUpdated: 'Jornada guardada con éxito',
  RecognitionErrorRejected: 'Error de reconocimento rechazado',
  RecognitionErrorApproved: 'Error de reconocimiento aprobado',
  TemplateDeleted: 'Plantilla eliminada con éxito',
  TemplateCreated: 'Plantilla creada con éxito',
  TemplateUpdated: 'Plantilla guardada con éxito',
  HierarchyDeleted: 'Jerarquía eliminada con éxito',
  HierarchyAdded: 'Jerarquía creada con éxito',
  HierarchyError: 'Error al crear jerarquía',
  CreateHierarchy: 'Crear jerarquía',
  WorkdayDeleted: 'Jornada eliminada con éxito',
  WorkshiftEdit: 'Edición de Jornada',
  CalendarUpdated: 'Datos guardados con éxito',
  BranchUpdated: 'Sucursal actualizada con éxito',
  ActiveEmployees: 'Activos',
  InactiveEmployees: 'Inactivos',
  BranchCreated: 'Sucursal creada con éxito',
  ErrorCreatingBranchDuplicated: 'Error al crear sucursal, ya existe una sucursal con este nombre',
  ErrorCreatingBranch:
    'Error al crear sucursal, por favor verifique los datos y vuelva a intentarlo',
  invalidEmailError: 'Por favor, ingrese un email válido',
  emailOrUsername: 'Email o Usuario',
  loginRequiredError: 'Por favor, ingrese su correo electrónico o usuario',
  loginPasswordRequiredError: 'Por favor, ingrese su contraseña',
  invalidNumber: 'El valor debe ser un número',
  forgotPasswordTitle: 'Recuperar tu contraseña',
  forgotUsernameTitle: 'Recuperar tu usuario',
  Recurrence: 'Recurrencia',
  AllDay: 'Dia completo',
  forgotPasswordButtonSend: 'Recuperar',
  forgotPasswordButtonBack: 'Volver al Inicio',
  PositionAlreadyExists: 'El puesto ya existe',
  HierarchyAlreadyExists: 'La jerarquía ya existe',
  forgotPasswordEmailSent: 'Enviamos un mensaje a tu casilla de correo.',
  forgotPasswordExpired: 'Este enlace ha caducado.',
  forgotPasswordSendAgain: 'Volver a enviar',
  loginSuccess: 'Has iniciado sesión con éxito!',
  CreateArticle: 'Crear artículo',
  inventoryType: 'Tipos de artículo',
  UserMustHaveValidEmail: 'El usuario debe tener un email válido para ser administrador',
  UniqueCode: 'Código único',
  MEDIUM: 'Media',
  HIGH: 'Alta',
  MarksReview: 'Revisión de marcas',
  ReviewAndApprovals: 'Revision y aprobaciones',
  rainAdvice1: 'Para mañana anuncian un',
  rainAdvice2: 'de probabilidades de lluvia,',
  rainAdvice3: 'en el pasado los días de lluvia',
  rainAdvice4: 'el ausentismo fué un',
  rainAdvice5: 'superior',
  rainAdvice6: 'al promedio',
  specialdayAdvice1: 'que viene es feriado',
  specialdayAdvice2: '¿Sabías que en fechas cercanas a un feriado',
  specialdayAdvice3: 'el ausentismo se incrementa en un',
  The: 'El',
  specialdayAdvice4: 'con respecto al promedio?',
  loginErrorNotAdmin:
    'No está autorizado para acceder al panel de administrador.Por favor pongase en contacto con el servicio de soporte.',
  NOT_ADMIN:
    'No está autorizado para acceder al panel de administrador.Por favor pongase en contacto con el servicio de soporte.',
  loginErrorNoToken:
    'Ha ocurrido un error. Por favor pongase en contacto con el servicio de soporte.',
  resetPasswordEmailError: 'No pudimos encontrar una cuenta asociada a ese email.',
  changePasswordTitle: 'Modifica tu contraseña',
  changePasswordRequiredError: 'Por favor, ingrese su nueva contraseña',
  changePasswordCompare: 'Las contraseñas no coinciden.',
  changePasswordSuccess: 'Contraseña modificada con éxito.',
  'Start hour': 'Hora de inicio',
  'Finish hour': 'Hora de fin',
  "End can't be lower than start": 'La hora de fin no puede ser menor al la hora de inicio',
  Invited: 'Invitado',
  Inviteds: 'Invitados',
  WeeklyReport: 'Reporte semanal',
  'Create event': 'Crear evento',
  'Edit event': 'Editar evento',
  'Type of event': 'Tipo de evento',
  'Name of the event': 'Nombre del evento',
  DurationInMinutes: 'Duración en minutos',
  'Select calendar': 'Seleccionar calendario',
  changePasswordError:
    'Su solicitud a vencido. Por favor vuelva a solicitar un cambio de contraseña.',
  ExtraHours: 'Horas extra',
  RestHours: 'Horas de descanso',
  createdAt: 'fecha',
  employeeUpdateFail: 'Ha ocurrido un error. Revise los datos y vuelva a intentarlo por favor.',
  employeeUpdateSuccess: 'Cambios guardados con éxito.',
  Events: 'Eventos',
  'Type of events': 'Tipo de eventos',
  available: 'Disponible',
  assigned: 'Asignado',
  maintainment: 'En mantenimiento',
  Availability: 'Disponibilidad',
  'Calendar conection': 'Conexión del calendarios',
  'Configurate availability': 'Configurar disponibilidad',
  'Configurate your weekly available hours': 'Configurar sus horarios semanales',
  'Times overlap with another set of times': 'Los horarios no pueden pisarse entre sí',
  ReviewMark: 'Revisión',
  Clone: 'Clonar',
  Connect: 'Conectar',
  FaceToFace: 'Cara a cara',
  Another: 'Otro',
  'Location/Medium': 'Ubicación/Medio',
  Connected: 'Conectado',
  Low: 'Baja',
  Origin: 'Origen',
  SeeMark: 'Ver marca',
  ValidateMark: 'Validar marca',
  MarkUpdatedSuccessfully: 'La marca fue actualizada con éxito',
  ValidatedMark: 'Marca validada',
  DeleteMark: 'Eliminar marca',
  DayOffCreated: 'Solicitud creada, pendiente de revisión.',
  DayOffUpdated: 'Solicitud actualizada, pendiente de revisión.',
  DayOffNotUpdated: 'Ha ocurrido un error. Revise los datos y vuelva a intentarlo por favor.',
  DayOffNotCreated: 'Ha ocurrido un error. Revise los datos y vuelva a intentarlo por favor.',
  SearchFailed: 'Ha ocurrido un error. Revise los datos y vuelva a intentarlo por favor.',
  generalFiles: 'Archivos Generales',
  paycheck: 'Recibos de sueldos',
  certificates: 'Certificados',
  YouHaveAlredyBeenAssignedARequest: 'Ya tienes asignada una solicitud para la misma fecha',
  ThisRequestExceedsTheMaximumDays: 'Dicha solicitud supera lo máximo de días permitidos.',
  medicalCertificates: 'Certificados Médicos',
  contracts: 'Contratos',
  files: 'archivos',
  Settlement: 'Liquidación',
  file: 'Archivo',
  recover: 'Recuperar',
  RequestDeleted: 'La solicitud fue eliminada con éxito',
  recoverPassword: 'Recuperar contraseña',
  backLogin: 'Volver al Login',
  'Forbidden resource': 'Permiso denegado',
  enterEmail: 'Por favor ingresa una dirección de correo.',
  AnErrorHasOcurred: 'Ha ocurrido un error. Revise los datos y vuelva a intentarlo por favor.',
  AbsencesRequests: 'Solicitudes de licencias',
  Rotation: 'Rotación',
  'Hires and dismisses': 'Contrataciones y bajas',
  Hires: 'Contraciones',
  Dismiss: 'Bajas',
  'Payroll receipt': 'Recibo de nómina',
  AbsencesReport: 'Informe de ausencias',
  AbsenceRequestReport: 'Informe de solicitudes de ausencias',
  DateAndTimeEnd: 'Fecha y hora fin',
  Employee: 'Empleado',
  Colaborator: 'Colaborador',
  Responsible: 'Responsable',
  Advance: 'Avance',
  dateStart: 'Fecha inicio',
  dateEnd: 'Fecha fin',
  addRequest: 'Agregar solicitud',
  newRequest: 'Nueva solicitud',
  absenceReason: 'Motivo de ausencia',
  NewAbsence: 'Nueva Ausencia',
  COVIDAlert: 'Alerta COVID',
  Notification: 'Notificación',
  Notifications: 'Notificaciones',
  notification: 'notificación',
  notifications: 'notificaciones',
  Totaltime: 'Tiempo total',
  Administrators: 'Administradores',
  administrators: 'administradores',
  administratedBranches: 'Sucursales administradas',
  SearchImage: 'Arrastra aquí tus documentos o haz clic para buscarlos en tu ordenador.',
  Edit: 'Editar',
  NewAdmin: 'Nuevo Administrador',
  EditAdmin: 'Editar Administrador',
  EditProfile: 'Editar perfil',
  Approve: 'Aprobar',
  Reject: 'Rechazar',
  Type: 'Tipo',
  SuperiorLevel: 'Cargo superior',
  AddLevel: 'Agregar nivel',
  Level: 'Nivel',
  Levels: 'Niveles',
  level: 'nivel',
  levels: 'niveles',
  CompanyData: 'Datos de la empresa',
  AddBranch: 'Agregar sucursal',
  BranchData: 'Datos de la sucursal',
  ResponsibleData: 'Datos de responsable',
  LegalInformation: 'Información legal',
  name: 'Nombre',
  AdditionalInformation: 'Información adicional',
  BusinessName: 'Razón social',
  employerRegistration: 'Registros patronales',
  employerRecords: 'Registros patronales',
  TaxIdentificationNumber: 'Código de identificación fiscal',
  vacationItem: 'Matriz vacacional',
  considerVacationsSince: 'Contempla vacaciones desde',
  CollaboratorId: 'ID del colaborador',
  // OrganizationModel: 'Modelo de organización',
  TimeFormat: 'Formato horario',
  Language: 'Idioma',
  Languages: 'Idiomas',
  Address2: 'Dirección secundaria',
  Currency: 'Moneda',
  Country: 'País',
  Country2: 'País secundario',
  UsersQuantity: 'Cantidad de usuarios',
  Updates: 'Actualizaciones',
  updateSubscription: 'Actualizar suscripción',
  cancelSubscription: 'Cancelar suscripción',
  updateDate: 'Fecha de actualización',
  userQuantity: 'Cantidad de usuarios',
  unitCost: 'Costo unitario',
  total: 'Total',
  payDay: 'Fecha de cobro ',
  quantity: 'Cantidad',
  Quantity: 'Cantidad',
  state: 'Estado',
  Bill: 'Factura',
  Buyer: 'Comprador',
  HelpIsColab: 'Debe asignar el campo Sucursal de colaborador para utilizar esta función.',
  Order: 'Orden',
  Subtotal: 'Subtotal',
  Discount: 'Descuento',
  Tax: 'Impuesto',
  Payments: 'Pagos',
  Company: 'Empresa',
  EditCompany: 'Editar empresa',
  CompanyPicture: 'Foto de empresa',
  sureToDelete: '¿Seguro desea eliminar?',
  Price: 'Precio',
  Item: 'Artículo',
  AlsoLoginWith: 'O ACCEDER CON',
  CustomizableData: 'Datos personalizables',
  ContractAndSettlement: 'Contrato y liquidación',
  Turns: 'Turnos',
  Resume: 'Resumen',
  OwnerHelp: 'Tiene acceso a toda la plataforma. Puede crear otros Roles.',
  AdministratorHelp:
    'Puede administrar las sucursales que se le habiliten, no puede dar de baja el servicio.',
  ManagerHelp:
    'Tiene poder para editar y ver todo lo referente a los colaboradores dentro de una determinada sucursal.',
  ViewerReporterHelp:
    'Puede descargar informes y ver tableros de ausencias pero no puede editar datos.',
  OwnersData: 'Acceso a toda la plataforma y creación de roles',
  ManagersData: 'Gestiona edita datos referentes a los colaboradores de una sucursal',
  ViewersData: 'Visualización de datos y descarga de informes',
  AdminData: 'Administra las sucursales que se le habiliten',
  Contact: 'Contacto',
  LastMonth: 'Último mes',
  Family: 'Familia',
  Inventory: 'Inventario',
  MainPhone: 'Teléfono principal',
  PhoneType: 'Tipo de teléfono',
  PhoneType2: 'Tipo de teléfono secundario',
  WorkPhone: 'Teléfono laboral',
  SecondaryPhone: 'Teléfono secundario',
  Locality: 'Localidad',
  Locality2: 'Localidad secundaria',
  Neighbourhood: 'Barrio',
  Neighbourhood2: 'Barrio secundario',
  Block: 'Manzana',
  Block2: 'Manzana secundaria',
  Apartment: 'Departamento',
  Apartment2: 'Departamento secundario',
  AddressType: 'Tipo de domicilio',
  AddressType2: 'Tipo de domicilio secundario',
  SecondaryAddress: 'Domicilio secundario',
  MainAddress: 'Domicilio primario',
  OVER_LIMIT_USERS: 'Haz sobrepasado el límite de usuarios contratados',
  secondaryEmail: 'Email secundario',
  mainEmail: 'Email principal',
  AreaCode: 'Código de área',
  AreaCode2: 'Código de área secundario',
  CountryCode: 'Código de país',
  CountryCode2: 'Código de país secundario',
  RetirementFund: 'Caja de jubilación',
  Ticket: 'Boleto',
  FamilyMember: 'Familiar',
  NameAndSurname: 'Nombre y apellido',
  InclusionProgram: 'Programa de inclusión',
  ExemptionOfContributions: 'Exoneración de aportes',
  WeeklyHours: 'Horas semanales',
  Review: 'Revisado por',
  Disability: 'Discapacidad',
  Colaborators: 'Colaboradores con capacidades especiales',
  CompanyMerger: 'Fusión empresa',
  UnemploymentInsurance: 'Seguro desempleo',
  MealVoucher: 'Vale de almuerzo',
  TypeOfPayment: 'Tipo de pago',
  typeOfBank: 'Tipo de banco',
  typeOfJourney: 'Tipo de jornada',
  MethodOfPayment: 'Método de pago',
  VacationsGroup: 'Grupo de vacaciones',
  contractingRegime: 'Régimen de contratación',
  infonavit: 'Infonavit',
  infonavitDate: 'Fecha de infonavit',
  typeOfInfonavit: 'Tipo de infonavit',
  // SettlementDay: "Fecha de cobro"
  SalarySlip: 'Recibos',
  BankCode: 'Código del banco',
  AccountToBeDebited: 'Cuenta a debitar de la empresa',
  SettlementData: 'Datos de liquidación',
  CurrentSituation: 'Situación actual',
  TypeOfWithdrawal: 'Tipo de baja',
  ExitDate: 'Fecha de egreso',
  WorkSchedule: 'Régimen de horario',
  Schedule: 'Horarios',
  Holidays: 'Feriados',
  EditHoliday: 'Editar feriados',
  AddHoliday: 'Añadir feriados',
  NewTemplate: 'Nueva plantilla',
  STARTS_AT_INGRESS_DATE: 'Inicio con el ingreso del Empleado',
  STARTS_ONE_YEAR_AFTER_EMPLOYEE_INGRESS_DATE: 'Comienza un ciclo después del ciclo del empleado',
  STARTS_TWO_YEAR_AFTER_EMPLOYEE_INGRESS_DATE: 'Comienza un ciclo después del ciclo del empleado',
  FINISH_ONE_YEAR_AFTER_EMPLOYEE_INGRESS_DATE: 'Finalizan al Cumplir 1 Ciclo',
  FINISH_TWO_YEAR_AFTER_EMPLOYEE_INGRESS_DATE: 'Finalizan al Cumplir 2 Ciclos',
  NEVER_EXPIRES: 'Nunca finalizan',
  NewShiftTemplate: 'Nueva plantilla de turnos',
  Colores: 'Colores',
  addNewShift: 'Añadir turno',
  selectDayShift: 'Selecciona un día para agregar el  horario',
  downloadAsImage: 'Descargar como imagen',
  downloadAsPDF: 'Descargar como PDF',
  day1: 'L',
  day2: 'M',
  day3: 'M',
  day4: 'J',
  day5: 'V',
  day6: 'S',
  day0: 'D',
  _day1: 'Lun',
  _day2: 'Mar',
  _day3: 'Mie',
  _day4: 'Jue',
  _day5: 'Vie',
  _day6: 'Sab',
  _day0: 'Dom',
  Jan: 'Ene',
  Feb: 'Feb',
  '01d': 'Despejado',
  '01n': 'Despejado',
  '02d': 'Parcialmente nublado',
  '02n': 'Parcialmente nublado',
  '03d': 'Nubes dispersas',
  '03n': 'Nubes dispersas',
  '04d': 'Parcialmente nublado',
  '04n': 'Parcialmente nublado',
  '09d': 'Aguacero',
  '09n': 'Aguacero',
  '10d': 'Lluvia',
  '10n': 'Lluvia',
  '11d': 'Tormenta',
  '11n': 'Tormenta',
  '13d': 'Nieve',
  '13n': 'Nieve',
  '50d': 'Neblina',
  '50n': 'Neblina',
  min: 'Mínima',
  max: 'Máxima',
  wind: 'Viento',
  see_rofile_button: 'Ver perfil',
  addShift: 'Añadir turno',
  Days: 'Días',
  DAYS: 'Días',
  MONTHS: 'Meses',
  YEARS: 'Años',
  errorFaceID: 'Error face ID',
  quoteGroup: 'Grupo de cotización',
  quoteNumber: 'Número de cotización',
  typeOfInventory: 'Tipo de inventarios',
  inventory: 'Inventario',
  Add: 'Agregar',
  Search: 'Buscar',
  healthcareSystem: 'Sistema de salud',
  SummaryNoData: 'Todavía no hay datos',
  irpfDeduction: 'Deducción IRPF',
  additionalSolidarityFund: 'Adicional fondo de solidaridad',
  solidarityFund: 'Fondo de solidaridad',
  retirementContributions: 'Aportes jubilatorios',
  unionAgreement: 'Convenio sindical',
  TextHere: 'Texto aquí',
  laborUnion: 'Sindicato',
  Liquidity: 'Liquidación',
  typesOfLiquidation: 'Tipo de liquidación',
  typeOfLeave: 'Tipo de baja',
  typesOfLeave: 'Tipos de baja',
  confirmShiftDelete: 'Se eliminará un turno ¿Desea confirmar dicha acción?',
  typeOfContract: 'Tipo de contrato',
  typesOfContract: 'Tipos de contrato',
  currentSituation: 'Situación actual',
  HierarchyUpdated: 'Jerarquía actualizada con éxito',
  AdminUpdated: 'Administrador actualizado con éxito',
  holidayGroup: 'Tipo de jerarquías',
  hierarchies: 'Jerarquías',
  typeOfSpecialDay: 'Tipo de día especial',
  StartBreaks: 'Inicio y finalización - Descanso',
  StartFirstBreak: 'Inicio y finalización - Primer descanso',
  StartSecondBreak: 'Inicio y finalización - Segundo descanso',
  WorkedHours: 'Horas trabajadas',
  Meeting: 'Reunión',
  EditBranch: 'Editar sucursal',
  Vacations: 'Vacaciones',
  // ByBenefitVacations: 'Vacaciones por beneficio',
  // ByContractVacations: 'Vacaciones por contrato',
  USER_WITHOUT_VACATION_ITEM: 'El usuario no tiene asignada una matriz vacacional',
  USER_WITHOUT_INGRESS_DATE: 'El usuario no tiene asignada una fecha de ingreso',
  DeleteRequestError: 'Error en eliminar',
  CurrentMonth: 'Mes actual',
  Monday: 'Lunes',
  Tuesday: 'Martes',
  Wednesday: 'Miercoles',
  Thursday: 'Jueves',
  Friday: 'Viernes',
  Saturday: 'Sábado',
  Sunday: 'Domingo',
  ABSENCE: 'Ausencias',
  PRECENSE: 'Presencias',
  LATE_ARRIVAL: 'Llegadas tarde',
  // "Total of collaborators": "Total de colaboradores",
  PRESENTISM: 'Presentismo',
  PRESENTISM_NOT_DATA: 'No hay datos en esta sucursal',
  PRECENSE_NOT_DATA: 'No hay presencias en esta sucursal',
  ABSENCE_NOT_DATA: 'No hay ausencias en esta sucursal',
  LATE_ARRIVAL_NOT_DATA: 'No hay llegadas tarde en esta sucursal',
  Weekday: ' - ',
  WeekDay0: 'Domingo',
  Commentaries: 'Comentarios',
  'New commentary': 'Nuevo comentario',
  COMMENTARY: 'Comentario',
  WeekDay1: 'Lunes',
  WeekDay2: 'Martes',
  WeekDay3: 'Miércoles',
  WeekDay4: 'Jueves',
  WeekDay5: 'Viernes',
  WeekDay6: 'Sábado',
  // WeekDay7: 'Domingo',
  true: 'SI',
  false: 'NO',
  'Bussiness name': 'Razón social',
  CreateWorkday: 'Añadir jornada',
  StartWorkday: 'Inicio jornada',
  EndWorkday: 'Fin jornada',
  'Days worked': 'Dias trabajados',
  'Rest days worked': 'Dias francos trabajados',
  'Late arrival days': 'Dias con llegadas tarde',
  'Approved absences': 'Licencias de día completo',
  'Licencias por horas': 'Licencias por horas',
  'To work hours': 'Horas a trabajar',
  'Worked hours': 'Horas trabajadas',
  'Normal worked hours': 'Horas normales',
  'Early arrival hours': 'Horas de llegada temprana',
  'Late arrival hours': 'Horas de llegada tarde',
  LateArrivalHours: 'Horas de llegada tarde',
  'Rest hours': 'Horas de descanso',
  'Rest extra hours': 'Horas extra descanso',
  RestExtraHours: 'Horas extra descanso',
  'Early leave hours': 'Salida temprana',
  'Extra hours': 'Horas extra',
  'Night hours': 'Horas nocturnas',
  'Night extra hours': 'Horas extra nocturnas',
  'Extra 2.5 hours': 'Horas extra 2.5',
  'Rest day hours': 'Horas trabajadas en feriado',
  bussinessName: 'Razón social',
  StartRest: 'Inicia descanso',
  EndRest: 'Finaliza descanso',
  EditWorkday: 'Editar jornada',
  CurrentTrimester: 'Trimestre actual',
  CurrentYear: 'Año actual',
  Last3months: 'Últimos 3 meses',
  Month: 'Mes',
  AllinOrder: 'Todo en orden',
  Colour: 'Color',
  Workshift: 'Turno',
  ProfessionalDeductions: 'Deducciones profesionales',
  CreateWorkshift: 'Nuevo turno',
  NewShift: 'Nuevo turno',
  EditWorkshift: 'Editar turno',
  NewWorkshift: 'Nueva jornada',
  CreateBranch: 'Crear sucursal',
  SimpleWorkshift: 'Jornada simple',
  SelectADay: 'Seleccione un día para elegir una hora',
  ApplyShiftWeek: 'Aplicar turno para esta semana',
  ApplyShiftEveryWeek: 'Aplicar turno para cada semana',
  AdvancedWorkshiftOptions: 'Edición de jornadas avanzadas',
  SuperiorBranch: 'Sucursal superior',
  SecondaryBranch: 'Sucursal secundaria',
  POSITION_NOT_EXISTS: 'El Puesto / Cargo no está disponible',
  AdvancedWorkshift: 'Jornadas avanzadas',
  Hours: 'Horas',
  Worked: 'trabajadas',
  BackToSimpleShift: 'Volver a jornadas simples',
  NoCovidLine1: 'No se han detectado potenciales casos',
  NoCovidLine2: 'de COVID-19 en la jornada.',
  Sunny: 'Soleado',
  Humidity: 'Humedad',
  Weather_description:
    'Se espera un cielo parcialmente despejado. La temperatura máxima será de 29°.',
  Calendar: 'Calendario',
  PendingTasks: 'Tareas pendientes',
  Reviewed: 'Revisadas',
  Priority: 'Prioridad',
  PendentTask_description: 'tiene una solicitud de ausencia pendiente de ser revisada.',
  organization_chart_title_card1:
    '¿Sabías que con Fichap podés visualizar \n y editar el organigrama de tu empresa?',
  organization_chart_description_card1:
    'Conoce las diferentes cadenas de jerarquías en tu equipo con la ayuda de un organigrama estructurado de forma automática.',
  organization_chart_title_card2:
    'Toda la información de tus colaboradores \n 100% digital y en la nube',
  organization_chart_title_card2_1: 'enviar notificaciones',
  organization_chart_title_card2_2: 'a todo tu equipo?',
  organization_chart_description_card2:
    'Mantén informados a todos tus colaboradores con las alertas de notificación y facilita la gestión de tus equipos de trabajo.',
  organization_chart_title_card3:
    'Lleva un control de los materiales \n que entregas a tu personal',
  organization_chart_title_card3_1: 'niveles',
  organization_chart_title_card3_2: 'de tu',
  organization_chart_title_card3_3: 'organización',
  organization_chart_description_card3:
    'Con Fichap, puedes organizar las jerarquías y niveles de tu organización de una forma muy fácil y automática.',
  organization_chart_title_card4: 'Gestiona los turnos y días libres de tus colaboradores',
  organization_chart_title_card4_1: 'recibir informes',
  organization_chart_description_card4:
    'Mejora las estadísticas y potencia tu gestión recibiendo un informe semanal en tu bandeja de entrada de todo lo sucedido en tu organización. Además, podrás personalizarlos según las necesidades de tu empresa.',
  know_more_button: 'Saber más',
  DragItemTo: 'Arrastra un elemento',
  addItToCalendar: 'para agregarlo al calendario',
  BreakLenght: 'Duración del descanso',
  elementarySchool: 'Educación Primaria',
  highSchool: 'Educación  Secundaria',
  RejectedAbsences: 'Ausencias rechazadas',
  'Image is required': 'El campo imagen no puede estar vacío',
  associateDegree: 'Educación Terciaria',
  bachelorDegree: 'Educación Universitaria',
  masterDegree: 'Master',
  Select: 'Seleccione',
  ONE_ABSENCE: 'Ausencia',
  EndTheAbsenceOf: 'Ha seleccionado finalizar la ausencia de',
  doctoralDegree: 'Doctorado',
  PendingRequests: 'Solicitudes pendientes',
  EditReason: 'Editar motivo',
  antUpload1: 'Arrastra el archivo a esta área para cargarlo',
  antUpload2: 'o haga clic aquí para agregar.',
  cropImage: 'Recorta la Imagen',
  Upload2: 'Cargar',
  DeleteImage: 'Eliminar imagen',
  By: 'De',
  PersonalImageError: 'Ha ocurrido un error, la imagen no se pudo modificar.',
  August: 'Agosto',
  October: 'Octubre',
  January: 'Enero',
  February: 'Febrero',
  March: 'Marzo',
  April: 'Abril',
  May: 'Mayo',
  June: 'Junio',
  July: 'Julio',
  November: 'Noviembre',
  December: 'Diciembre',
  DAYOFF_COLLIDES: 'Ya tienes asignada una solicitud para la misma fecha',
  September: 'Septiembre',
  Dec: 'Dic',
  Apr: 'Abr',
  Water: 'Agua',
  Trash: 'Residuos',
  Trees: 'Árboles',
  Dissatisfied: 'Disconformidad',
  DISSATISFIED: 'Disconformidad',
  Dissatisfieds: 'Disconformidades',
  GoToBillboard_1: 'Comunícate con tus colaboradores',
  GoToBillboard_2: ' de manera efectiva a través de la ',
  'Total of collaborators': 'Total de colaboradores',
  'Go to billboard': 'Ir a la cartelera',
  daily: 'Diario',
  weekly: 'Semanal',
  Satisfied: 'Conformidad',
  SATISFIED: 'Conformidad',
  quarterly: 'Quincenal',
  biweekly: 'Trimestral',
  monthly: 'Mensual',
  yearly: 'Anual',
  halfYearly: 'Semestral',
  RequestNumber: 'Solicitud N°',
  EditRequestNumber: 'Editar solicitud N°',
  By2: 'por',
  Salary: 'Sueldos',
  Settlements: 'Liquidaciones',
  NewSettlement: 'Nueva liquidación',
  Period: 'Período',
  CreateSettlement: 'Crear liquidación',
  companyBank: 'Banco a debitar',
  Seniority: 'Antigüedad',
  RejectMotive: 'Elige el motivo de rechazo.',
  RejectMotive2: 'Motivo de rechazo',
  NewReason: 'Añadir motivo',
  AbsenceReason: 'Motivo de ausencia',
  errorFaceIdEmpty: '¡Bien! No tienes revisiones pendientes.',
  Pendings: 'Pendientes',
  'Absentism by branches': 'Ausentismo por sucursales',
  'Types of absences': 'Tipo de ausencias',
  TypeOfDayoffCreated: 'Tipo de ausencia creada con éxito',
  Dayoff: 'Licencias',
  Yes: 'Si',
  No: 'No',
  NoDataYet: 'Sin datos aún',
  AddReason: 'Añadir motivo',
  InternPhone: 'Número interno',
  AddNewValueToThisField: 'Añadir un valor para este campo',
  month1: 'Ene',
  month2: 'Feb',
  month3: 'Mar',
  month4: 'Abr',
  month5: 'May',
  month6: 'Jun',
  month7: 'Jul',
  month8: 'Ago',
  month9: 'Sep',
  month10: 'Oct',
  month11: 'Nov',
  month12: 'Dic',
  'Average duration of the day weekly work': 'Duración media de la jornada laboral semanal',
  'Average duration of the day weekly work plan':
    'Duración media de la jornada laboral planificada semanal',
  HealthCareByLaw: 'Obra social por ley',
  TheoricalHours: 'Horas teóricas',
  FUTURE_DATE_NOT_VALID: 'No se puede crear una jornada a futuro',
  Week: 'Semana',
  'Text here': 'Texto aquí',
  'Texto aquí': 'Texto aquí',
  SeeMore: 'Ver más',
  InCourse: 'En curso',
  ReportErrorBreakIn: 'Reporte de error de inicio de descanso.',
  ReportErrorBreakOut: 'Reporte de error de finalización de descanso.',
  ReportErrorCheckIn: 'Reporte de error de inicio de jornada.',
  ReportErrorCheckOut: 'Reporte de error de finalización de jornada.',
  isSuspended: '¿Esta suspendido?',
  FAQ: 'Preguntas frecuentes',
  TerminatedEmployees: 'Colaboradores de baja',
  Handbooks: 'Manuales',
  TooLong: '¡Demasiado largo!',
  SelectAnOption: 'Seleccione una opción',
  Tutorials: 'Tutoriales',
  ClickToWatchTooltip: 'Haz click para ver el video',
  SinceLastYear: 'desde el año pasado',
  Mobile: 'Movil',
  SelectEmployees: 'Seleccionar colaboradores',
  PleaseSelectAnEmployee: 'Por favor, seleccione un colaborador',
  PleaseSelectAReason: 'Por favor, seleccione un motivo',
  isRequired: 'Este campo es obligatorio',
  Landline: 'Telefono fijo',
  Fax: 'Fax',
  Daily2: 'Diario',
  MinutesShort: 'min',
  Minutes: 'Minutos',
  adminErrorJ001: 'the detected face is not in the collection',
  adminErrorJ002: 'el número de documento es inválido',
  adminErrorJ003: 'el email es inválido',
  adminErrorJ004: 'el usuario ya existe',
  adminErrorJ005: 'el email ya existe',
  SaveChanges: 'Guardar Cambios',
  Countries: 'Países',
  AddressStreetAndNumber: 'Dirección (calle y número)',
  CreateSpecialDay: 'Crear feriado',
  BillboardActivity: 'Actividad cartelera',
  Licenses: 'Licencias',
  Wasted: 'Usados',
  Availables: 'Disponibles',
  TypeOfAbsences: 'Tipo de ausencias',
  Miércoles: 'Miércoles',
  'Visualiza y edita los turnos de cada colaborador.':
    'Visualiza y edita los turnos de cada colaborador.',
  Marzo: 'Marzo',
  Docket: 'Legajo',
  ALL_DAYS_GENERATES_AT_SAME_TIME: 'Todos a la vez',
  DAYS_GENERATES_DAILY: 'Generación diaria',
  DAYS_GENERATES_MONTHLY: 'Generación mensual',
  ONE_CYCLE_SINCE_INGRESS: 'Un ciclo desde el ingreso del empleado',
  STARTS_AND_FINISH_AT_DATE: 'Comienza y termina en un período fijo',
  // STARTS_AT_SPECIFIC_DATE: 'Inicia en fecha determinada a partir del segundo ciclo',
  SAME_VACATIONS: 'Finaliza en el período determinado',
  'Comienza en el período determinado': 'Comienza en el período determinado',
  STARTS_AT_DETERMINATED_PERIOD: 'Comienza en el período determinado',
  STARTS_ONE_YEAR_LATER_AT_DETERMINATED_PERIOD: 'Comienza un ciclo después del período determinado',
  DAYS_GENERATES_EVERY_20_DAYS: 'Generación cada 20 días hábiles',
  // DAYS_GENERATES_EVERY_20_DAYS: 'Generación cada 20 días trabajados',
  MarkHistory: 'Historial de marcas',
  StartDateAndTime: 'Fecha y hora de inicio',
  EndDateAndTime: 'Fecha y hora de fin',
  Approveds: 'Aprobadas',
  Rejecteds: 'Rechazadas',
  ExternalIDAlreadyExists: 'External ID ya está en uso',
  adminErrorJ006: 'la sucursal del colaborador no puede estar vacía',
  adminErrorJ007: 'el role está vacío o es inválido',
  adminErrorJ008: 'sucursales permitidas es requerido',
  adminErrorJ009: 'username ya existe',
  adminErrorJ010: 'username es inválido',
  adminError: 'Ocurrió un error, verifica que los campos sean válidos',
  'owner user cannot be removed': 'Un Owner no puede ser eliminado',
  ErrorInPlan:
    'Ocurrió un error intentando de cambiar el plan, para más información comunicarse con support@fichap.com',
  NotDeleted: 'El usuario no se eliminó',
  adminErrorJ011: 'email is requerido',
  PlanChangedSuccessfully: 'Los datos fueron actualizados con éxito!',
  ' ': ' ',
  ManageAndCustomizeGeneralSettings: 'Administre y personalice la configuración general',
  ServiceEnablement: 'Habilitación de servicios',
  AllowBreaks: 'Permitir descansos',
  AllowClocking: 'Permitir fichar',
  EnableAutomaticDayClosure: 'Habilitar cierre de jornada automática',
  accidents: 'Accidentes',
  Permissions: 'Permisos',
  AllowOffLocationClockingIn: 'Permitir geocerco',
  Subscriptions: 'Suscripciones',
  AllowClockingInWithoutAnAssignedShift: 'Permitir fichaje sin turno asignado',
  AllowClockingInInCaseOfSuspensions: 'Permitir fichaje en caso de suspensíon',
  SUSPENSION: 'Suspensión',
  WARNING: 'Apercibimiento',
  BOND: 'Bono',
  Bond: 'Bono',
  AllowClockingInWithApprovedAbsence: 'Permitir fichaje con ausencia aprobada',
  ToleranceEarlyEntry: 'Tolerancia de entrada temprana (en minutos)',
  Tolerance: 'Tolerancia',
  ExampleUse: 'Ejemplo de uso',
  UnitCost: 'Costo unitario',
  Developers: 'Desarrolladores',
  ApiEnabledFunctions: 'Las funciones habilitadas para utilizar por API se encuentran en:',
  viewDocuments: 'Ver documentación',
  apiExplanation:
    'La API de Fichap utiliza claves de API keys para autenticar solicitudes.  Puede ver, actualizar y copiar sus claves en el Dashboard. ',
  apiUnderlineMessage: 'Al generar un nuevo token, el código anterior no podrá utilizarse.',
  journeyDetail: 'Detalle de jornada',
  'Hours detail': 'Detalle de horas',
  'Indexes comparison': 'Comparativa de índices',
  statusWorkday: 'Cierre de jornada',
  LateClockIn: 'Horas de llegada tarde',
  _FileNumber: 'Legajo',
  earlyClockOut: 'Salida temprana',
  NOT_DATE: 'Fecha inválida',
  Rest: 'Descanso',
  nightExtraHours: 'Horas extra nocturnas',
  'isActive-true': 'Activo',
  'isActive-false': 'Inactivo',
  Demographic: 'Demográfico',
  extraHours25: 'Horas extra 2.5',
  STARTED: 'Iniciada',
  PAUSE: 'Pusada',
  FINISH: 'Finalizada',
  AUTOMATIC_FINISH: 'Cierre automatico',
  CANCEL: 'Cancelada',
  CHANGE: 'Editada',
  ShifstWillBeLost: 'Se perderán los turnos asignados al colaborador',
  BRANCH_NOT_VALID: 'Sucursal inválida',
  TYPE_NOT_VALID: 'Tipo inválido',
  AVAILABLE: 'Disponible',
  NO_AVAILABLE: 'No disponible',
  IN_MAINTENANCE: 'En mantenimiento',
  DATE_BEGINS: 'Fecha de Inicio',
  DATE_ENDS: 'Fecha de Finalización',
  CREATE_INVENTORY_TYPE: 'Crear tipo de Inventario',
  CREATE: 'Create',
  EditInventoryType: 'Editar tipo',
  SEARCH_ARTICLE: 'Buscar Artículo',
  ARTICLE: 'Artículo',
  NEW_ARTICLE: 'Nuevo Artículo',
  AssignedArticles: 'Artículos asignados',
  ASSIGNED: 'Asignado',
  CreateAndAssign: 'Crear y asignar',
  History: 'Historial',
  Assignation: 'Asignación',
  UNSUBSCRIBE: 'Dar de baja',
  ASSIGN_ARTICLE: 'Asignar artículo',
  EDIT_ARTICLE: 'Editar artículo',
  MustBerNumber: 'Debe ser un Numero',
  AdditionalIndications: 'Indicaciones adicionales',
  DescriptionOfTheBranch: 'Descripción de la sucursal',
  TimeTable: 'Horario',
  ModifiedTimeTable: 'Horario modificado',
  PreviusTimeTable: 'Horario previo',
  Generation: 'Generación',
  TradeMarkHistory: 'Historial de marcas',
  ActionType: 'Tipo de accion',
  ClockIn: 'Inicio De Jornada',
  ClockOut: 'Fin De Jornada',
  StartBreak: 'Inicio De Descanso',
  EndBreak: 'Fin De Descanso',
  Offline: 'Offline',
  Manual: 'Manual',
  Automatic: 'Automatico',
  Billboard: 'Cartelera',
  AdministratorWithMoreArticlesPosted: 'Administrador con más notas publicadas',
  PublishedArticles: 'Notas Publicadas',
  ArticlesRead: 'Notas Leídas',
  Billboardactivity: 'Actividad cartelera',
  FichapUser: 'Usuario Fichap',
  BranchesAllowed: 'Sucursales Permitidas',
  MostReadCategory: 'Categoría más leída',
  MostReadArticle: 'Nota mas leída',
  Author: 'Autor',
  Publications: 'Publicaciones',
  Categories: 'Categorías',
  informationalBanner: 'Banner Informativo',
  Posted: 'Publicadas',
  Drafts: 'Borradores',
  PaperBin: 'Papelera',
  NewinformativeBanner: 'Nuevo Banner Informativo',
  EditinformativeBanner: 'Editar Banner Informativo',
  CoverImage: 'Imagen de portada',
  Preview: 'Vista previa',
  BackgroundColor: 'Color de fondo',
  SaveDraft: 'Guardar borrador',
  Colors: 'Colores',
  Publish: 'Publicar',
  EditPost: 'Editar publicación',
  DestineURL: 'URL de destino',
  NewPost: 'Nueva publicación',
  SavedAsDraft: 'Guardado como borrador',
  SaveAsDraft: 'Guardar borrador',
  Template: 'Plantilla',
  TypeJourney: 'Tipo de jornada',
  SuccessfulPublication: 'Publicación exitosa',
  notImageError: 'La imagen es requerida',
  "Selected image can't be heavier than 1mb": 'La imagen seleccionada no puede pesar más de 1mb',
  weekday1: 'L',
  weekday2: 'M',
  weekday3: 'M',
  weekday4: 'J',
  weekday5: 'V',
  weekday6: 'S',
  Weekday0: 'D',
  Weekday13letters0: 'Dom',
  Weekday2: 'Lun',
  Weekday3letters2: 'Mar',
  Weekday4y3letters3: 'Mie',
  Weekday5: 'Jue',
  Weekday6y3letters5: 'Vie',
  weekday3letters6: 'Sab',
  allM: 'Todos', // siguiendo el patron del html entity &ordm; y &ordf;
  allF: 'Todas',
  'holiday.history.availableDays': 'Vacaciones acumuladas',
  'holiday.history.tooltip': 'Cantidad de días agregados desde carga masiva de acumulado',
  'leader-firstName': 'Nombre de líder',
  'leader-lastName': 'Apellido de líder',
  'leader-document': 'Número de documento de líder',
  'leader-position': 'Puesto del líder',
  'leader-positionExternalId': 'ID del puesto del líder',
  PositionId: 'ID del puesto',
  suspendvacation: 'Suspende licencia vacacional',
  special: 'Licencia especial',
  ValueMoney: 'Valor monetario ($)',
  Percentage: 'Porcentaje (%)',
  United: 'Unidades (0-9)',
  'Assignation collide': 'La asignación colisiona con otra',
  PORCENTAGE: 'Porcentaje (%)',
  CURRENCY: 'Valor monetario ($)',
  INTEGER: 'Unidades (0-9)',
  SIMPLE_SHIFT: 'Simple',
  CUSTOM_SHIFT: 'Personalizado',
  REST: 'Descanso',
  SPECIAL_DAY: 'Festivo',
  SUNDAY_SHIFT: 'Dominical',
  HOURS_EXTRA_HOUR: 'Horas extra',
  HOURS_SPECIAL_DAY: 'Horas trabajadas en festivo',
  HOURS_SUNDAY: 'Horas dominicales',
  HOURS_SUNDAY_EXTRA: 'Horas extra dominicales',
  HOURS_RESTDAY: 'Horas trabajadas en franco',
  HOURS_RESTDAY_EXTRA: 'Horas extra en franco',
  HOURS_SPECIAL_DAY_EXTRA: 'Horas extra trabajadas en festivo',
}

let append = Arr.dot(fichapFormMessages, 'FichapForm')
messages = { ...messages, ...append }

export default {
  locale: 'es-ES',
  localeAntd,
  messages,
}
