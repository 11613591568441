import localeAntd from 'antd/es/locale/en_US'
import moment from 'moment'
import Arr from '../helpers/Arr'
import fichapFormMessages from './en-US/fichapForm'

let messages = {
  Dashboard: 'Dashboard',
  Team: 'Team',
  TeamMembers: 'Team members',
  absencesDashboard: 'Absences dashboard',
  Shift: 'Shift',
  Advance: 'Advance',
  Shifts: 'Shifts',
  shifts: 'shifts',
  rainAdvice1: 'For tomorrow there is an',
  NotPermission: "You don't have permission to realize this action",
  rainAdvice2: 'chance of rain,',
  rainAdvice3: 'in the past on rainy days,',
  rainAdvice4: 'absenteeism wa',
  rainAdvice5: 'higher',
  rainAdvice6: 'than average',
  'La suscripción fue cancelada con éxito.': 'The subscription was canceled successfully.',
  'Puede reactivar su suscripción y recuperar todas las funciones profesionales en cualquier momento':
    'You can activate your subscription and retrieve all professional features at any time',
  Aceptar: 'Accept',
  '¿Cuál es el motivo por el cual está cancelando la suscripción?':
    'What is the reason you are canceling the subscription?',
  'Cancelar suscripción': 'Cancel subscription',
  specialdayAdvice1: 'Next Monday is a holiday',
  specialdayAdvice2: 'Did you know that on dates close to a holiday',
  specialdayAdvice3: 'absenteeism increases by',
  specialdayAdvice4: 'compared to the average?',
  AssignTemplate: 'Assign template',
  SelectTemplate: 'Select a template',
  SelectShiftTemplateFromTo: 'Select from which date you want to add the template (optional)',
  WorkedShifts: 'Worked shifts',
  shift: 'shift',
  addBreak: 'Add break',
  EarlyLeave: 'Early leave',
  Nocturnas: 'At night',
  WorkshiftReport: 'Workshifts report',
  TotalHours: 'Total hours',
  BranchOfficesColaborator: 'Branch offices colaborator',
  EditFichapUser: 'Edit fichap user',
  NewFichapUser: 'New Fichap user',
  BranchOfficesAllowed: 'Branch offices allowed',
  AdressStreetAndNumber: 'Address (street and number)',
  Department: 'Department',
  AdditionalIndicators: 'Additional indicators',
  PostalCode: 'Postal Code',
  OwnerHelp: 'You have access to the entire platform. You can create other Roles.',
  FichapUsers: 'Fichap Users',
  AdministratorHelp:
    'You can manage the branches that are enabled for you, you cannot cancel the service.',
  ManagerHelp:
    'You have the power to edit and view everything related to the collaborators within a given branch.',
  Rol: 'Role',
  Location: 'Location',
  HelpIsColab: 'You must assign the input branch colaborator to use this function.',
  ViewerReporterHelp:
    'You can download reports and view absence dashboards but you cannot edit data.',
  OwnersData: 'Access to the entire platform and role creation',
  AdminData: 'Manages the branches that are enabled',
  ManagersData: 'Manages edits data concerning the employees of a branch office',
  ViewersData: 'Data visualization and report download',
  'Compartir enlaces del tipo de evento con los eventos programados':
    'Share event type links with scheduled events',
  'Aún no hay tipos de eventos': 'Not any type of events yet',
  'Aún no hay eventos': 'No events yet',
  EarlyArrival: 'Early arrival',
  EditColumn: 'Edita columns',
  TypeOfHierarchies: 'Type of hierarchies',
  GoToBillboard_1: 'Comunicate with your employees',
  GoToBillboard_2: ' effectively through the ',
  'Utilizando Fichap, has contribuído al ahorro de recursos naturales':
    'Using Fichap, has contributed to the saving of natural resources',
  'Error en asignación masiva': 'Error in massive asign',
  'Total de colaboradores:': 'Total of collaborators',
  Entendido: 'Agreed',
  NewManualWorkday: 'New manual workday',
  WeeklyReport: 'Weekly report',
  Create: 'Create',
  Heatmap: 'Heatmap',
  Próximos: 'Next',
  Pasados: 'Previous',
  FaceToFace: 'Face to face',
  LegalInformation: 'Legal information',
  CompanyData: 'Company data',
  BulKLoadFile: 'bulk-load-fichap',
  Contact: 'Contact',
  Settlement: 'Settlement',
  SalarySlip: 'Salary slip',
  Salary: 'Salaries',
  NoTINFound: 'No Tax identification number found',
  Settlements: 'Settlements',
  NewSettlement: 'New settlement',
  Period: 'Period',
  CreateSettlement: 'Create settlement',
  // Collaborator: "Collaborator",
  // Document: "Document",
  // State: "State",
  SettlementDay: 'Settlement day',
  CreateCategory: 'Create category',
  NewCategory: 'New category',
  deleteCategoryDesc: 'The category will be deleted definitely, do you want to delete it anyway?',
  UploadImage: 'Upload image',
  NoMatches: 'No matches',
  Matches: 'Matches',
  BulkLoad: 'Bulk load',
  Workday: 'Workday',
  manualWorkday: 'Manual workday',
  Collaborator: 'Collaborator',
  ManualWorkdayError: 'Manual workday creation failed',
  ManualWorkdayCreated: 'Manual worday created',
  BulkLoadError: 'Failed to load data, please check that the branch names match',
  BulkLoadSuccessFull: 'Bulk upload completed successfully',
  LastMonth: 'Last month',
  heatmap: 'Heatmap',
  Timeline: 'Timeline',
  AssignShifts: 'Add shift',
  MassiveAssign: 'Massive assign',
  UpdatePosition: 'Update position',
  DailyShifts: 'Shifts',
  Positions: 'Positions',
  SearchByTitleOrId: 'Search by title or ID',
  Organigram: 'Organigram',
  NightExtraHours: 'Night extra hours',
  ReviewErrors: 'Review the errors found, edit them and confirm the action.',
  UpdateDataManually: 'Confirmation of data',
  organigram: 'organigram',
  PositionUpdated: 'Position updated',
  ExternalID: 'External ID',
  workshiftTheoric: 'Theoretical workshift',
  CreatePosition: 'Create position',
  PositionTypes: 'Position types',
  Apply: 'Apply',
  SpecificWorkshift: 'Specific work shift',
  RestrictShift: 'Block sign in in without an assigned shift',
  IgnoreDayoff: 'Allow sign in in with scheduled absence',
  NightShiftControl: 'Allow night hours',
  Assign: 'Assign ',
  ShiftType: 'Shift type',
  SimpleShift: 'Simple shift',
  SelectWorkdays: 'Select workdays',
  SelectAShift: 'Select a shift',
  CustomShift: 'Custom shift',
  NightShiftRange: 'Night shift range',
  CustomWorkshift: 'Custom work shift',
  File: 'File',
  _Document: 'ID',
  ShiftTemplateAssigned: 'Shift template assigned',
  LateEntryTime: 'Late clock in',
  Back: 'Previous',
  GoBack: 'Go back',
  Month: 'Month',
  Day: 'Day',
  Next: 'Next',
  casualtylist: 'Casualty list',
  Group: 'Group',
  AddHoliday: 'Add holiday',
  Hirearchy: 'Hirearchy',
  Hirearchies: 'Hirearchies',
  HirearchiesMap: 'Hirearchies map',
  workshift: 'Work shift',
  SelectDataToDisplay: 'Select the data you want to display',
  'workshift-this-week': 'Work shift',
  'shift-this-week': 'Work shift',
  InsertHierarchy: 'Insert hierarchy',
  EmployeesReport: 'Employees report',
  UpdateHierarchy: 'Edit hierarchy',
  SuperiorHirearchy: 'Superior hirearchy',
  AddHirearchy: 'Add hirearchy',
  Update: 'Update',
  Main: 'Main',
  Familiar: 'family',
  file: 'File',
  Requests: 'Requests',
  requests: 'requests',
  NoPendingRequests: 'Great! You have no pending requests.',
  Profile: 'Profile',
  Reports: 'Reports',
  ShiftsReport: 'Shifts report',
  Help: 'Help',
  Branch: 'Branch',
  BranchOffices: 'Branch Office',
  BranchID: 'Fichap Point ID',
  FichapPointID: 'Fichap point ID',
  SpecialDays: 'Special days',
  SuperiorBranch: 'Superior branch',
  Average: 'Average',
  Pendient: 'Pending',
  Posicion: 'Position',
  'Puesto / Cargo': 'Position',
  Posição: 'Position',
  Position: 'Position',
  RequestRejected: 'Request rejected successfully',
  RequestApproved: 'Request approved successfully',
  RequestDeleted: 'The request was successfully deleted',
  ApprovedRequestError: 'Error approving request',
  RejectRequestError: 'Error rejecting request',
  DaysWorked: 'Days Worked',
  EmployeesData: 'Employees data',
  SpecialWorkedDays: 'Special days worked',
  SpecialDaysNotWorked: 'Special days not worked',
  DetailedShiftReport: 'Detailed shift report',
  GeneralShiftReport: 'General shift report',
  daysWorked: 'Days worked',
  HoursWorked: 'Hours Worked',
  loginError: 'Login failed, invalid username or password',
  Hours: 'Hours',
  Worked: 'worked',
  HoursLate: 'Hours late',
  SecondaryBranch: 'Secondary branch',
  Settings: 'Settings',
  DetailedAbsence: 'Detailed absence',
  Absences: 'Absences',
  absences: 'absences',
  Subscription: 'Subscription',
  Companies: 'Companies',
  Admin: 'Admin',
  Managers: 'Managers',
  team: 'Team',
  add: 'Add',
  import: 'Import',
  allFields: 'Total data',
  contactData: 'Contact',
  contractData: 'Contract',
  labourData: 'Labour',
  personalData: 'Personal',
  familyData: 'Family',
  organizationData: 'Organization',
  showingResultsShowing: 'Showing records from ',
  showingResultsTo: 'to ',
  showingResultsOf: 'of a total of ',
  showingResultsRecords: 'records',
  StartTimeMustBeBefore: 'Start time must be earlier than end time',
  StartDateMustBeBefore: 'Start date must be earlier than end date',
  Image: 'Image',
  EditAssignment: 'Edit assignment',
  StartDate: 'Start Date',
  startDate: 'Start Date',
  EndDate: 'End Date',
  endDate: 'End Date',
  startHour: 'Start hour',
  endHour: 'End hour',
  StartBreaks: 'Beginning and end - Break',
  StartFirstBreak: 'Beginning and end - First break',
  StartSecondBreak: 'Beginning and end - Second break',
  SelectFile: 'Select file',
  SelectTypeOfRequest: 'Select the type of request',
  Date: 'Date',
  Reason: 'Reason',
  Reasons: 'Reasons',
  reason: 'reason',
  reasons: 'reasons',
  Sickness: 'Sickness',
  New: 'New',
  Unavailability: 'Unavailability',
  Colaborator: 'Employee',
  CreateNotification: 'Create notification',
  CreateBranch: 'Create branch',
  RejectMotive2: 'Reject motive',
  CreateShift: 'Create shift',
  SelectExistingShift: 'Select template of shifts',
  CreateRequest: 'Create request',
  NoSupportingAttachment: 'No supporting attachment',
  DoYouWishToReinstate: 'Do you wish to reinstate this employee?',
  AreYouSureToDeleteBranch: 'Do you wish to delete this branch?',
  Send: 'Send',
  Yes: 'Yes',
  No: 'No',
  Attach: 'Attach',
  Lastname: 'Lastname',
  AbsenceReason: 'Absence reason',
  FirstLastname: 'First lastname',
  NewReason: 'New reason',
  AddReason: 'Add reason',
  SecondLastname: 'Second lastname',
  MotherLastname: 'Mother lastname',
  ProfilePicture: 'Profile picture',
  DocumentExpirationDate: 'Document expiration date',
  LegacyNumber: 'Employee Code',
  ExeededTheMaximumDaysAllowedForReason:
    'You have already exceeded the maximum days allowed for that reason for request',
  NumberOfRequest: 'Number of request',
  IdentificationCard: 'Identification card',
  Divorced: 'Divorced',
  Ups: 'Ups!',
  Progress: 'Progress',
  Actions: 'Actions',
  Name: 'Name',
  FirstName: 'First name',
  SecondName: 'Middle name',
  DateOfBirth: 'Date of Birth',
  Address: 'Address',
  Address2: 'Secondary address',
  City: 'City',
  City2: 'Secondary city',
  State: 'State',
  Employeestate: 'State',
  EmployeesDeletedSuccess: 'Employees were successfully deleted',
  theEmailIsInUse: 'The email you entered is already in use. Try another one',
  theDocumentIsInUse: 'The number of document that you entered is already in use. Try another one',
  DoYouWishToTerminateTheService:
    'Do you wish to permanently terminate the service of these employees?',
  DoYouWishToTerminateEmployee: 'Do you wish to permanently terminate this employee?',
  OnlyLettersAndNumbersPlease: 'Number and letters only!',
  LocationState: 'State',
  LocationState2: 'Secondary state',
  Presences: 'Presences',
  Presenteeism: 'Presenteeism',
  Absenteeism: 'Absenteeism',
  AbsenteeismRate: 'Absenteeism Rate',
  CurrentAbsenteeismRate: 'Current absenteeism rate',
  AbsenteeismType: 'Absenteeism Type',
  ZipCode: 'Zip Code',
  ZipCode2: 'Secondary zip Code',
  Gender: 'Gender',
  Female: 'Female',
  Male: 'Male',
  Other: 'Other',
  Married: 'Married',
  Concubine: 'Concubine',
  Single: 'Single',
  Widower: 'Widower',
  DateOfCreation: 'Date of creation',
  TypeOfDayoff: 'Type of absence',
  ForHours: 'For hours',
  CompleteDay: 'Complete day',
  MarksReview: 'Marks review',
  ValidatedMark: 'Validated mark',
  MarkUpdatedSuccessfully: 'The mark was successfully updated',
  Phone: 'Phone',
  Document: 'ID Number',
  TypeDocument: 'Type of Document',
  typeOfHierarchies: 'Type of hierarchies',
  BranchPicture: 'Branch picture',
  SuperiorOrganization: 'Superior organization',
  SelectedDateCannotBeEarlier: 'The selected date cannot be earlier than the current date',
  SelectedDateCannotBeTheSame: 'The selected dates cannot be the same',
  TimeZone: 'Time zone',
  Email: 'Email',
  Password: 'Password',
  NewPassword: 'New password',
  RepeatPassword: 'Repeat your new password',
  EditPassword: 'Edit password',
  Nationality: 'Nationality',
  CountryOfBirth: 'Country of Birth',
  CityOfBirth: 'City of Birth',
  CivilStatus: 'Civil Status',
  StudyLevel: 'Study level',
  Language: 'Language',
  Languages: 'Languages',
  LastUpdate: 'Last update',
  OriginalTime: 'Original time',
  createdAt: 'date',
  Origin: 'Origin',
  SeeMark: 'See mark',
  ValidateMark: 'Validate mark',
  DeleteMark: 'Delete mark',
  Modified: 'Modified',
  PunchEdition: 'Mark update',
  Nick: 'Nick',
  PersonalData: 'Personal Data',
  Personal: 'Personal',
  Street: 'Street',
  StreetNumber: 'StreetNumber',
  Floor: 'Floor',
  Floor2: 'Secondary floor',
  Save: 'Save changes',
  Summary: 'Summary',
  FileNumber: 'File Number',
  Category: 'Category',
  QuoteGroup: 'Quote Group',
  DateOfAdmission: 'Date of Admission',
  SocialSecurityNumber: 'SSN',
  SSN: 'SSN',
  Role: 'Role',
  position: 'Position',
  LaborUnion: 'Labor Union',
  Bank: 'Bank',
  CBU: 'CBU',
  Alias: 'Alias',
  MainBranch: 'Main Branch',
  EndEmploymentContract: 'End of Employment Contract',
  InternalPhone: 'Internal Phone',
  YearsWorked: 'Years Worked',
  less1year: 'Less than 1 year',
  _1to5years: '1 to 5 years',
  _6to10years: '6 to 10 years',
  _11to20years: '11 to 20 years',
  _20years: '20+',
  EndTrialPeriod: 'End of Trial Period',
  ReportsTo: 'Reports To',
  WorkData: 'Work Data',
  GeneralData: 'General data',
  Collaborators: 'Collaborators',
  collaborators: 'employees',
  CollaboratorId: 'Collaborator ID',
  BillingInformation: 'Billing Information',
  Billing: 'Billing',
  EmergencyContact: 'Emergency Contact',
  Link: 'Link',
  Hourly: 'Hourly',
  Daily: 'Daily',
  Monthly: 'Monthly',
  Annual: 'Annual',
  Biannual: 'Biannual',
  Select: 'Select',
  Weekly: 'Weekly',
  Quarterly: 'Quarterly',
  DeleteShift: 'Delete shift',
  BulkLoadItemDeleted: 'Row deleted successfully',
  DeleteTodayShifts: `Today's shifts will be eliminated ${moment().format(
    'MM-DD-YYYY',
  )}. Do you want to confirm this action?`,
  ImagesLoaded: 'Images loaded',
  DeleteWeekShifts: `Shifts from ${moment()
    .startOf('week')
    .format('MM-DD-YYYY')} to ${moment()
    .endOf('week')
    .format('MM-DD-YYYY')} will be eliminated. Do you want to confirm this action?`,
  DeleteMonthShifts: `Shifts for the current month will be eliminated. Do you want to confirm this action?`,
  DeleteAllShifts: `All future shifts will be eliminated. Do you want to confirm this action?`,
  DeleteSelectedShifts: `Selected shifts will be eliminated. Do you want to confirm this action?`,
  AccountNumber: 'Account Number',
  TypeOfRemuneration: 'Type of Remuneration',
  BulkLoadImageDone: 'Bulk load ended successfully',
  PaymentFrequency: 'Payment Frequency',
  SelectOne: 'Select one',
  InAgreement: 'In agreement',
  Soon: 'Soon',
  ComingSoon: 'ComingSoon',
  OutOfAgreement: 'Out of agreement',
  selectDayShift: 'Select a day to add the schedule',
  Work: 'Work',
  Documents: 'Documents',
  Break: 'Break',
  AddDocuments: 'Add document',
  GeneralDocumentsChart: 'General documents chart',
  LoadedBy: 'Loaded by',
  UploadDate: 'Upload date',
  AddAbsence: 'Add absence',
  Age: 'Age',
  TaxIdentification: 'Tax identification',
  TaxIdentificationNumber: 'Tax identification number',
  MedicalCoverage: 'Medical Coverage',
  MedicalCoverageChosen: 'Medical coverage chosen',
  DepartureDate: 'Departure Date',
  UnionAgreement: 'Union Agreement',
  AdvancedFilters: 'Advanced filters',
  ContactData: 'Contact Data',
  MaternityPaternityLeave: 'Maternity/Paternity Leave',
  SickLeave: 'Sick Leave',
  UnexcusedAbsence: 'Unexcused Absence',
  VacationAbsence: 'Vacation Absence',
  StudyAbsence: 'Study absence',
  Others: 'Others',
  Status: 'Status',
  Approved: ' Approved',
  APPROVED: ' Approved',
  MANTAINANCE: 'On mantainance',
  ArticleUnassigned: 'Assignment successfully finished',
  ArticleAssigned: 'Article assigned successfuly',
  ApprovedAbsences: 'Approved absences',
  notApprovedAbsences: 'Not approved absences',
  Rejected: 'Rejected',
  REJECTED: 'Rejected',
  inactive: 'INACTIVE',
  active: 'ACTIVE',
  SinceLastMonth: 'since last month',
  SinceLastWeek: 'since last week',
  LateEntry: 'Late entry',
  LateArrivalTime: 'Late arrival amount of time',
  MoreThanAllowed: 'More than allowed',
  MoreThan: 'Break more than',
  Allowed: ' allowed',
  Title: 'Title',
  Description: 'Description',
  Kilometers: 'Kilómeters',
  Folder: 'Folder',
  Signs: 'Signs',
  Cancel: 'Cancel',
  NewFile: 'New file',
  EditFile: 'Edit file',
  EditAdmin: 'Edit admin',
  EditProfile: 'Edit profile',
  EditReason: 'Edit reason',
  EndTheAbsenceOf: 'You have selected to end the absence of',
  GeneralFiles: 'General files',
  AddDocument: 'Add document',
  EmptyAdvice: 'You don`t have any documents in this folder yet',
  MassiveUpload: 'Massive data upload',
  ImageImport: 'Image import',
  SelectColumn: 'Select column',
  IgnoreColumn: 'Ignore this column',
  Accept: 'Accept',
  Data: 'Data',
  Pictures: 'Pictures',
  SocialInsurance: 'Social insurance',
  Delete: 'Delete',
  Confirm: 'Confirm',
  Upload: 'Upload',
  SelectAll: 'Select all',
  AreYouSure: 'Are you sure?',
  ApprovedPics: 'Approved',
  DissaprovedPics: 'Dissaproved',
  Start: 'Start',
  START: 'START',
  StartWorkday: 'Workday start',
  EndWorkday: 'Workday end',
  Breaks: 'Breaks',
  StartRest: 'Rest time start',
  EndRest: 'Rest time end',
  End: 'End',
  END: 'END',
  FreeDay: 'Free day',
  Modify: 'Modify',
  MassiveImport: 'Massive import',
  Import: 'Import',
  DownloadModel: 'Download model',
  Download: 'Download',
  AppliedtoChartsandTables: 'Applied to charts and tables',
  Filter: 'Filter',
  OrganizationModel: 'Organization model',
  quoteNumber: 'Quote number',
  WorkEmail: 'Work email',
  AreYouAdministrator: 'Are you an administrator?',
  Mark: 'Checks / Mark',
  DateOfEntry: 'Date of entry',
  TypeContract: 'Type of contract',
  TypeLeave: 'Type of leave',
  HealthControl: 'Health Control',
  HealthInsurance: 'Health insurance code',
  Licenses: 'Licenses',
  FoodHandlingLicense: 'Food handling license',
  Contract: 'Contract',
  ActualSituation: 'Actual situation',
  Area: 'Area',
  VacationGroup: 'Vacation group',
  CostCenter: 'Cost center',
  Sector: 'Sector',
  Organization: 'Organization',
  SecondaryOrganization: 'Secondary organization',
  MainOrganization: 'Primary organization',
  organizationChart: 'Organization chart',
  ContractShift: 'Contract shift',
  ActiveCollaborators: 'Active employees',
  Union: 'Union',
  AccountType: 'Account type',
  Coin: 'Coin',
  ClockBranchoffice: 'Clock branch office',
  Health: 'Health',
  AllowCheckBranch: 'Allow clocking in with this branch',
  Mon: 'Mon',
  Tue: 'Tue',
  Wed: 'Wed',
  Thu: 'Thu',
  Fri: 'Fri',
  Sat: 'Sat',
  Sun: 'Sun',
  CompletedData: 'Completed data',
  ContractData: 'Contract data',
  EmploymentData: 'Employment data',
  UnionData: 'Union data',
  OrganizationData: 'Organization data',
  BillingData: 'Billing data',
  Women: 'Women',
  Men: 'Men',
  discount: 'Discount?',
  Discount_: 'Discount?',
  Avaliability: 'Avaliability',
  NonCustomizableFields: 'Non-customizable default fields',
  Neutral: 'Neutral',
  DiscoverFeatures: 'Discover all the features of your dashboard',
  FichapTutorials: 'In the Fichap tutorials you will find everything you need to know.',
  SeeNow: 'See now',
  Percentages: 'Percentages',
  YearlyAbsenteeism: 'Yearly absenteeism',
  Confirmation: 'Confirmation',
  DoYouWishToConfirm: 'Do you wish to confirm this action?',
  YouHaveSelected: 'You have selected',
  YouHaveSelectedTo: 'You have selected to unsubscribe',
  RequestsToApprove: 'requests to approve',
  RequestsIssued: 'Requests issued',
  RequestToApprove: 'request to approve',
  RequestsToReject: 'requests to reject',
  RequestToReject: 'request to approve',
  CurrentPeriodRequests: 'Total requests for the current period',
  LastYearRequests: 'Total applications from the current period of the previous year',
  Kinship: 'Kin',
  IncomeTax: 'Personal income tax deduction',
  From: 'From',
  To: 'To',
  AddFamily: 'Add family',
  Administrator: 'Administrator',
  AreYouAdmin: 'Are you administrator?',
  HealthData: 'Health data',
  AdministratorData: 'Administrator data',
  NewInventory: 'New inventory',
  AssignInventory: 'Assign inventory',
  SelectFreeDays: 'Select free days',
  AssignShift: 'Assign shift',
  RememberTheGreenDays: 'Remember that working days in green can also be selected as free days.',
  AssignScheduleFreeDay: 'Assign a schedule for each free day',
  HealthCareSystem: 'Health care system',
  CreateInventory: 'Create inventory',
  DailyWorkingHours: 'Daily working hours of all employees',
  VacationSchedule: 'Vacation and leave schedule by employee',
  VacationMatrix: 'Matriz vacacional',
  Vacations: 'Vacations',
  IllnesOfFamily: 'Illness of a family member',
  MaternityPaternity: 'Maternity/Paternity',
  DateAndTime: 'Date and time',
  DoesItImpact: 'Does it impact on attendance?',
  Pending: 'Pending',
  Pendings: 'Pendings',
  TerminatedEmployees: 'Terminated employees',
  PENDING: 'Pending',
  NoDataYet: 'No data yet',
  TypeOfDayoffCreated: 'The type of dayoff was successfully created',
  Nomine: 'Nomine',
  CompleteDataEmployees: 'Complete data of your employees',
  DemographicData: 'Demographic data',
  DemographicDataSubtitle: 'Location, gender, age, marital status, nationality.',
  WorkAccidents: 'Work accidents',
  EvolutionSixMonths: 'Evolution in the last 6 months',
  ErrorReporting: 'Error reporting',
  CustomReportFiltering: 'Custom report filtering',
  ChooseReport: 'Choose the parameters and set up your report',
  HistoryOfReports: 'History of requested reports',
  actionHistory: 'History',
  WouldLikeReport: 'Would you like a more customized report?',
  PleaseSelectAnEmployee: 'Please, select an employee',
  SelectEmployees: 'Select employees',
  PleaseSelectAReason: 'Please, select a reason',
  SelectAnOption: 'Select an option',
  FichapAllowsYou: 'Fichap allows you to create unique reports according to your companys needs.',
  ContactUs: 'Contact us',
  UniqueCode: 'Unique code',
  UserMustHaveValidEmail: 'User must have a valid email to be an Admin',
  LearnMore: 'Learn more',
  CustomReport: 'Custom report',
  EditInventory: 'Edit Inventory',
  EditBranch: 'Edit branch',
  DeselectAll: 'Deselect all',
  ApplyFilters: 'Apply filters',
  isRequired: 'This field is required',
  UploadImages: 'Image upload',
  Number: 'Number',
  Number2: 'Secondary number',
  Branches: 'Branches',
  Hierarchies: 'Hierarchies',
  AddBranch: 'Add branch',
  AddOrganization: 'Add organization',
  BranchData: 'Branch data',
  ResponsibleData: 'Responsible data',
  username: 'Username',
  IsACollab: 'Is a collaborator',
  Action: 'Action',
  FatigueOr: 'Fatige or',
  BodyDiscomfort: 'body discomfort',
  CoughOrDifficulty: 'Cough or difficulty',
  Breathing: 'breathing',
  HighTemperature: 'High temperature',
  Over37: 'Over 37.5°.',
  NasalCongestion: 'Nasal congestion',
  OrHeadache: 'or headache',
  CleanHands: 'Clean hands',
  SocialDistance: 'Social distance',
  AlwaysMask: 'Always wear a mask',
  Apt: 'Apt',
  NoSymptoms: 'No symptoms',
  Care: 'Care',
  PossibleSymptom: 'Possible symptom',
  Birthday: 'Birthday',
  DocumentSignature: 'Document signature',
  EmployeeAbsences: 'Employee absences',
  EmployeePayroll: 'New receipt notification',
  PossibleSymptomsOfCovid: 'Possible symptoms of COVID-19',
  YouWillRecieveAMessage:
    'If you are an administrator, you will receive a message each time an employee electronically signs a document.',
  WarningTemperature: 'Warning temperature',
  Symptoms: 'Symptoms',
  RequestTemperatureD: 'Request temperature when dialing',
  EnableInPoint: 'Enable in Point',
  EnableInMobilie: 'Enable in Mobile',
  AssignForThisWeek: 'Assign shift for this week',
  AssignForEveryWeek: 'Assign shift for every week',
  GeneralBreak: 'General break',
  CustomBreak: 'Custom break',
  ApplyDifferentBreak: 'Apply a different rest than the general rest',
  BREAK: 'BREAK',
  DaysAndHours: 'Days and hours',
  FaceRecognition: 'Face recognition',
  IdentificationDocument: 'Identification document',
  ACTIVE: 'ACTIVE',
  Observation: 'Observation',
  INACTIVE: 'INACTIVE',
  ActiveEmployees: 'Active',
  InactiveEmployees: 'Inactive',
  AdminRoles: 'Admin Roles',
  // AreYouAdministrator: 'Are you administrator?',
  Validation: 'Validation',
  AllowExtraHours: 'Allow extra hours',
  OvertimeTolerance: 'Extra-time tolerance (in minutes)',
  ToleranceLateArrival: 'Late check-in tolerance (in minutes)',
  ToleranceEarlyDeparture: 'Early check-out tolerance (in minutes)',
  RangeOfHoursRest: 'Range of hours for resting',
  BusinessName: 'Business name',
  FichapMobileMap: 'Fichap Mobile Map',
  RestButton: 'Rest button',
  EmployeePortal: 'Employee portal',
  RestrictLocationSignin: 'Restrict location signin',
  InternNumber: 'Intern number',
  workworkPhone: 'Work phone',
  QuotationGroup: 'Quotation group',
  SignMark: 'Sign / Mark',
  'footer.title': 'Fichap - Intelligent team management',
  'footer.subtitle': ' / All rights reserved',
  'login.title': 'Login with your data',
  'login.welcome': 'Welcome to Fichap',
  'login.rememberme': 'Remember me',
  'login.forgotpassword': 'Forgot password?',
  'login.forgotusername': 'Forgot username?',
  'login.login': 'Login',
  'login.createaccount': 'Don`t have an account?',
  'login.starttoday': 'Start today',
  supportchat: 'Support Chat',
  'login.accesswith': 'or access with',
  'item.title': 'Title',
  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Search...',
  'topBar.findPages': 'Find pages...',
  'topBar.actions': 'Actions',
  'topBar.status': 'Status',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.billingPlan': 'Plan',
  'topBar.profileMenu.role': 'Position',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Edit Profile',
  'topBar.profileMenu.logout': 'Logout',
  actionAttach: 'Attach',
  actionSend: 'Send',
  actionCalendar: 'Record',
  downloadAction: 'Download',
  actionView: 'View',
  actionViewProfile: 'View profile',
  actionDelete: 'Delete',
  actionCancel: 'Cancel',
  actionEdit: 'Edit',
  Disenroll: 'Disenroll',
  createEmployee: 'Create employee',
  TimeFormat: 'Time format',
  DateAndTimeStart: 'Start date and time',
  DateAndTimeEnd: 'End date and time',
  Meeting: 'Meeting',
  TextHere: 'Text here',
  saveEmployee: ' Save employee',
  Passport: 'Passport',
  invalidEmail: 'Invalid Email',
  requiredField: 'Required field',
  deleteEmployeeQuestion: 'Are you sure you want to delete?',
  deleteEmployeeConfirmed: 'Employee have been successfully deleted',
  SpecialDayDeleted: 'Holiday have been successfully deleted',
  SpecialDayUpdated: 'Holiday have been successfully updated',
  OrganizationalMap: 'Organizational map',
  deleteEmployeeError: 'An error has ocurred. Please Try again',
  CompanyErrorUpdated: 'An error has ocurred. Please Try again',
  UpsWeAreWorking: 'Ups! We are working...',
  ThePageYouAreLookingFor: 'The page you are looking for is currently',
  CreateFolder: 'Create folder',
  EditFolder: 'Edit folder',
  FolderCreated: 'The folder was successfully created',
  FolderDeleted: 'The folder was successfully deleted',
  FolderUpdated: 'The folder was successfully updated',
  WeWillBeBack: 'under maintenance, we will be back soon.',
  employeeCreationSuccess: 'Employee have been successfully created',
  employeeCreationError: 'An error has ocurred. Please Try again',
  SpecialDayCreated: 'Special day created',
  SpecialDayaErrorCreate: 'An error has ocurred. Please Try again',
  employeeCreationEmailFail: 'Email is already in use',
  emailOrUsername: 'Email or Username',
  invalidEmailError: 'Please, enter a valid email',
  loginRequiredError: 'Please enter your email or username',
  loginPasswordRequiredError: 'Please enter your password',
  AnErrorHasOcurred: 'An error has ocurred. Please Try again',
  invalidNumber: 'The value should be a number',
  forgotPasswordTitle: 'Recover your password',
  forgotUsernameTitle: 'Recover your username',
  forgotPasswordButtonSend: 'Recover',
  PositionAlreadyExists: 'Position already exists',
  HierarchyAlreadyExists: 'Hieararchy already exists',
  forgotPasswordButtonBack: 'Back to Login',
  forgotPasswordEmailSent: 'We sent a message to your mailbox.',
  forgotPasswordExpired: 'This link has expired.',
  forgotPasswordSendAgain: 'Send again.',
  CreateArticle: 'Create article',
  inventoryType: 'Type of articles',
  loginSuccess: 'You have successfully logged in!',
  loginErrorNotAdmin:
    'You are not authorized to access the Admin dashboard.Please contact support.',
  NOT_ADMIN: 'You are not authorized to access the Admin dashboard.Please contact support.',
  loginErrorNoToken: 'An error has ocurred. Pleast contact support.',
  resetPasswordEmailError: "We couldn't find an account associated with that email.",
  changePasswordTitle: 'Change your password',
  changePasswordRequiredError: 'Please enter your new password',
  changePasswordCompare: 'Password do not match.',
  changePasswordSuccess: 'Password changed successfully.',
  changePasswordError: 'Your request has expired. Please request a new password reset.',
  GeneralUpdated: 'Data saved successfully',
  CustomDataUpdated: 'Data saved successfully',
  HierarchyUpdated: 'Hierarchy updated succesfully',
  CompanyUpdated: 'The company data was successfully updated',
  CalendarUpdated: 'Data saved successfully',
  BranchUpdated: 'Branch updated successfully',
  ThePasswordMustContain:
    'The password must contain at least 8 characters, one letter and one number.',
  BranchCreated: 'Branch created succesfully',
  ErrorCreatingBranchDuplicated: 'Error creating branch, a branch with this name already exists',
  ErrorCreatingBranch: 'Error creating branch, please check the data and try again',
  PasswordUpdated: 'The password was successfully updated',
  AdminUpdated: 'Administrator updated successfully',
  ReasonCreated: 'Reason created successfully',
  ReasonUpdated: 'Reason updated successfully',
  ErrorCalendarUpdated: 'An error has ocurred. Please Try again',
  OVERLAPPING_DATES: 'An error has occurred. The selected shift overlaps with an existing shift',
  USER_NOT_FOUND: 'The user does not exist in the database or is disabled.',
  COMPANY_NOT_FOUND: 'The company does not exist in the database or is disabled.',
  BRANCHOFFICE_NOT_FOUND: 'The branch does not exist in the database or is disabled.',
  LOGIN_NOT_ALLOWED: 'You do not have permissions to login to this terminal.',
  ExtraHours: 'Extra hours',
  RestHours: 'Rest hours',
  AllDay: 'All day',
  ExtraTimeRest: 'Extra rest time',
  AddColumn: 'Add column',
  Monday: 'Monday',
  earlyHours: 'Early hours',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
  RestTime: 'Rest time',
  DayOfTheWeek: 'Day of the week',
  DefaultBreakTime: 'General break time',
  employeeUpdateFail: 'An error has occurred. Please review the details and try again.',
  employeeUpdateSuccess: 'Changes saved successfully.',
  NotificationsUpdated: 'Notification saved successfully',
  CovidUpdated: 'Data saved successfully',
  Revisions: 'Revisions',
  Upload2: 'Upload',
  RequestNumber: 'Request N°',
  EditRequestNumber: 'Edit request N°',
  HierarchyAdded: 'Hierarchy created successfully',
  HierarchyError: 'An error has ocurred. Please Try again',
  HierarchyDeleted: 'Hierarchy deleted successfully',
  WorkshiftDeleted: 'Workshift deleted successfully',
  WorkshiftCreated: 'Workshift created successfully',
  WorkshiftAssigned: 'Workshifts created succesfully',
  WorkshiftUpdated: 'Workshift updated successfully',
  TemplateDeleted: 'Template deleted successfully',
  TemplateCreated: 'Template created successfully',
  TemplateUpdated: 'Template updated successfully',
  WorkshiftEdit: 'Workshift edit',
  BranchDeleted: 'Branch deleted succesfully',
  IDAssignedShift: 'ID and assigned shift',
  AdminCreated: 'Admin created successfully',
  WorkdayCreated: 'Workday created successfully',
  WorkdayUpdated: 'Data saved successfully',
  adminNotDeleted: 'An error has ocurred. Please Try again',
  adminDeleted: 'Admin deleted successfully',
  ReasonDeleted: 'Reason deleted successfully',
  WorkdayDeleted: 'Workday deleted successfully',
  DayOffCreated: 'Request created, pending review.',
  CollaboratorUpdated: 'Data was successfully updated',
  GeneralError: 'An error has occurred',
  AssignDaysAndBreaks: 'Assign days, days off and breaks',
  DayOffUpdated: 'Request updated, pending review.',
  DoesItAllowPartTime: 'Does it allow part-time work?',
  DayOffNotCreated: 'An error has ocurred. Please Try again',
  DayOffNotUpdated: 'An error has ocurred. Please Try again',
  SearchFailed: 'An error has ocurred. Please Try again',
  generalFiles: 'General Files',
  PartialAbsence: 'Absence for hours only',
  FullDayAbsence: 'Full day absence',
  YouHaveAlredyBeenAssignedARequest: 'You have already been assigned a request on the same date',
  ThisRequestExceedsTheMaximumDays: 'This request exceeds the maximum number of days allowed.',
  paycheck: 'Paycheck',
  certificates: 'Certificates',
  medicalCertificates: 'Medical Certificates',
  contracts: 'Contracts',
  files: 'files',
  recover: 'Recover',
  recoverPassword: 'Recover password',
  backLogin: 'Back to login',
  enterEmail: 'Please enter an email address.',
  NewAbsence: 'New Absence',
  COVIDAlert: 'COVID Alert',
  TooLong: 'Too long!',
  Notification: 'Notification',
  Notifications: 'Notifications',
  notification: 'notification',
  notifications: 'notifications',
  Totaltime: 'Total time',
  AbsencesRequests: 'Absences Requests',
  AbsencesReport: 'Absences report',
  AbsenceRequestReport: 'Absences requests report',
  Employee: 'Employee',
  Responsible: 'Responsible',
  dateStart: 'Start Date',
  dateEnd: 'End Date',
  addRequest: 'Add request',
  newRequest: 'New request',
  absenceReason: 'Absence reason',
  Administrators: 'Administrators',
  administrators: 'administrators',
  Type: 'Type',
  SuperiorLevel: 'Superior level',
  AddLevel: 'Add level',
  Level: 'Level',
  Levels: 'Levels',
  level: 'level',
  levels: 'levels',
  NewAdmin: 'New Administrator',
  AlsoLoginWith: '',
  CustomizableData: 'Customizable data',
  ContractAndSettlement: 'Contract & Settlement',
  Turns: 'Turns',
  Resume: 'Resume',
  Family: 'Family',
  Inventory: 'Inventory',
  MainPhone: 'Main phone',
  WorkPhone: 'Work phone',
  // AdminRoles: 'Administrator roles',
  PhoneType: 'Phone type',
  PhoneType2: 'Secondary phone type',
  SecondaryPhone: 'Main phone',
  Locality: 'Locality',
  Locality2: 'Secondary locality',
  Neighbourhood: 'Neighbourhood',
  Neighbourhood2: 'Secondary neighbourhood',
  Block: 'Block',
  Block2: 'Secondary block',
  Apartment: 'Apartment',
  Apartment2: 'Secondary apartment',
  AddressType: 'Address type',
  AddressType2: 'Secondary address type',
  MainAddress: 'Main address',
  SecondaryAddress: 'Secondary address',
  TypeOfPayment: 'Type of payment',
  secondaryEmail: 'Secondary email',
  mainEmail: 'Main email',
  Country: 'Country',
  Country2: 'Secondary country',
  AreaCode: 'Area code',
  AreaCode2: 'Secondary area code',
  CountryCode: 'Country code',
  CountryCode2: 'Secondary country code',
  RetirementFund: 'Retirement fund',
  InclusionProgram: 'Inclusion program',
  ExemptionOfContributions: 'Exemption of contributions',
  CompanyMerger: 'Company merge',
  UnemploymentInsurance: 'Unemployment insurance',
  MealVoucher: 'Meal voucher',
  MethodOfPayment: 'Method of payment',
  VacationsGroup: 'Vacations group',
  BankCode: 'Bank code',
  AccountToBeDebited: 'Account to be debited',
  SettlementData: 'Settlement data',
  CurrentSituation: 'Current situation',
  TypeOfWithdrawal: 'Type of withdrawal',
  ExitDate: 'Exit date',
  WorkSchedule: 'Work schedule',
  FamilyMember: 'Family member',
  NameAndSurname: 'Name and surname',
  WeeklyHours: 'Weekly hours',
  Review: 'Review',
  Holidays: 'Holidays',
  EditHoliday: 'Edit holidays',
  addNewShift: 'Add new shift',
  Lu: 'Mo',
  Ma: 'Tu',
  Mi: 'We',
  Ju: 'Th',
  Vi: 'Fr',
  Sa: 'Sa',
  Do: 'Su',
  OrganizationChart: 'Organization chart',
  downloadAsImage: 'Download as image',
  downloadAsPDF: 'Download as PDF',
  DeleteRequestError: 'Delete request error',
  day1: 'M',
  day2: 'T',
  day3: 'W',
  day4: 'T',
  day5: 'F',
  day6: 'S',
  day0: 'S',
  _day1: 'Mon',
  _day2: 'Tue',
  _day3: 'Wed',
  _day4: 'Thu',
  _day5: 'Fri',
  _day6: 'Sat',
  _day0: 'Sun',
  Jan: 'Jan',
  Feb: 'Feb',
  see_rofile_button: 'See profile',
  addShift: 'Add shift',
  Days: 'Days',
  errorFaceID: 'Error face ID',
  quoteGroup: 'Quote group',
  typeOfInventory: 'Inventory type',
  typesOfLiquidation: 'Liquidation type',
  healthcareSystem: 'Healthcare system',
  irpfDeduction: 'IRPF Deduction',
  additionalSolidarityFund: 'Additional Solidarity fund',
  solidarityFund: 'Solidarity fund',
  retirementContributions: 'Retirement Contributions',
  inventory: 'Inventory',
  unionAgreement: 'Union Agreement',
  laborUnion: 'Labor union',
  typesOfLeave: 'Types of leave',
  typeOfContract: 'Contract type',
  typesOfContract: 'Contract types',
  Colour: 'Colour',
  Search: 'Search',
  ProfessionalDeductions: 'Professional deductions',
  CreateWorkday: 'Create workday',
  EditWorkday: 'Edit workday',
  EditShift: 'Edit shift',
  ASuperiorBranchWasRemoved: 'A superior branch was removed',
  SelectASuperiorBranch: 'Select a new "Superior branch" for these branches',
  Edit: 'Edit',
  Add: 'Add',
  NewShift: 'New shift',
  currentSituation: 'Current situation',
  holidayGroup: '',
  Workshift: 'Workshift',
  hierarchies: 'Hierarchies',
  CreateWorkshift: 'Create workshift',
  CreateTemplate: 'Create template',
  UpdateTemplate: 'Edit template',
  NewWorkshift: 'New workshift',
  SimpleWorkshift: 'Simple workshift',
  SelectADay: ' Select a day in order to pick a time',
  ApplyShiftWeek: 'Apply to this week',
  ApplyShiftEveryWeek: 'Apply to every week',
  AdvancedWorkshiftOptions: 'Advanced workshift options',
  BackToSimpleShift: 'Back to simple shift',
  AdvancedWorkshift: 'Advanced workshift',
  EditWorkshift: 'Edit workshift',
  WorkedHours: 'Hours worked',
  CurrentMonth: 'Current month',
  CurrentTrimester: 'Current trimester',
  CurrentYear: 'Current year',
  Last3months: 'Last 3 months',
  View: 'View',
  AllinOrder: 'All in order',
  NoCovidLine1: 'No potential cases',
  NoCovidLine2: 'of COVID-19 on the day',
  Sunny: 'Sunny',
  Humidity: 'Humidity',
  Temperature: 'Temperature',
  Weather_description: 'A partially clear sky is expected. The maximum temperature will be ',
  Calendar: 'Calendar',
  PendingTasks: 'Pending tasks',
  PendingRequests: 'Pending requests',
  People: 'People',
  PendentTask_description: 'you have an absence request pending review.',
  organization_chart_title_card1:
    'Did you know that with Fichap you can visualize the organizational chart of your company?',
  organization_chart_title_card2:
    'Did you know that with Fichap you can send notifications to your entire team?',
  organization_chart_title_card3:
    'Did you know that with Fichap you can manage all the levels of your organization?',
  organization_chart_title_card4:
    'Did you know that you can receive a weekly report in your inbox?',
  know_more_button: 'Know more',
  confirmShiftDelete: 'A shift will be deleted. Do you want to confirm this action?',
  DragItemTo: 'Drag an item to',
  addItToCalendar: 'add it to the calendar',
  BreakLenght: 'Break length',
  elementarySchool: 'Elementary School',
  highSchool: 'High School',
  associateDegree: "Associate's Degree",
  bachelorDegree: "Bachelor's Degree",
  masterDegree: "Master's Degree",
  SummaryNoData: 'Oops! There is no data',
  doctoralDegree: 'Doctoral Degree',
  antUpload1: 'Drag file to this area to upload',
  antUpload2: 'or click here to add',
  DeleteImage: 'Delete image',
  cropImage: 'Crop the Image',
  ValidUser: 'Valid username',
  Checking: 'Checking',
  SaveChanges: 'Save Changes',
  BillboardActivity: 'Billboard activity',
  AddressStreetAndNumber: 'Address street and number',
  CreateSpecialDay: 'Create special day',
  UserAlreadyExists: 'Username already exists',
  TypeOfAbsences: 'Type of absences',
  Docket: 'Docket',
  MarkHistory: 'Absences history',
  StartDateAndTime: 'Start date and time',
  Approveds: 'Approveds',
  ExternalIDAlreadyExists: 'External id elready exists',
  EndDateAndTime: 'End date and time',
  adminErrorJ001: 'the detected face is not in the collection',
  adminErrorJ002: 'the number of document is invalid',
  adminErrorJ003: 'the email is invalid',
  adminErrorJ004: 'the user already exists',
  adminErrorJ005: 'the email already exists',
  adminErrorJ006: 'the main branch is null or invalid',
  adminErrorJ007: 'roles is empty or invalid',
  adminErrorJ008: 'the administrated branches is empty',
  adminErrorJ009: 'the username already exists',
  adminErrorJ010: 'the username is invalid',
  adminErrorJ011: 'email is required',
  NotDeleted: 'User not deleted',
  adminError: 'An Error occurs, please verificate the data',
  Today: 'Today',
  ThisWeek: 'This week',
  All: 'All',
  August: 'August',
  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  November: 'November',
  December: 'December',
  daily: 'Daily',
  weekly: 'Weekly',
  quarterly: 'Quartely',
  monthly: 'Monthly',
  halfYearly: 'Half yearly',
  yearly: 'Yearly',
  PersonalImageError: 'An error occurred, the image could not be modified.',
  October: 'October',
  September: 'September',
  Selected: 'Selected',
  By: 'By',
  By2: 'by',
  companyBank: 'Bank to be debited',
  Seniority: 'Seniority',
  RejectMotive: 'Choose the reason for rejection.',
  InternPhone: 'Intern number',
  AddNewValueToThisField: 'Add a value to this field',
  month1: 'JAN',
  month2: 'FEB',
  month3: 'MAR',
  month4: 'APR',
  month5: 'MAY',
  month6: 'JUN',
  month7: 'JUL',
  month8: 'AUG',
  month9: 'SEP',
  month10: 'OCT',
  month11: 'NOV',
  month12: 'DEC',
  errorFaceIdEmpty: 'Yeah! You have no pending reviews.',
  '01d': 'Clear sky',
  '01n': 'Clear sky',
  '02d': 'Few clouds',
  '02n': 'Few clouds',
  '03d': 'Scattered clouds',
  '03n': 'Scattered clouds',
  '04d': 'Broken clouds',
  '04n': 'Broken clouds',
  '09d': 'Shower rain',
  '09n': 'Shower rain',
  '10d': 'Rain',
  '10n': 'Rain',
  '11d': 'Thunderstorm',
  '11n': 'Thunderstorm',
  '13d': 'Snow',
  '13n': 'Snow',
  '50d': 'Mist',
  '50n': 'Mist',
  min: 'High',
  max: 'Low',
  wind: 'Wind',
  HealthCareByLaw: 'Medical coverage by law',
  Week: 'Week',
  Manage: 'Manage',
  Company: 'Company',
  'Text here': 'Text Here',
  'Texto aquí': 'Text Here',
  SeeMore: 'See more',
  InCourse: 'In course',
  ReportErrorBreakIn: 'Break in error report',
  ReportErrorBreakOut: 'Break out error report',
  ReportErrorCheckIn: 'Check in error report',
  ReportErrorCheckOut: 'Check out error report',
  CheckIn: 'Clock in',
  CheckOut: 'Clock out',
  RestCheckIn: 'Rest Clock in',
  RestCheckOut: 'Rest Clock out',
  CheckIn1: 'Clock in 1',
  CheckOut1: 'Clock out 1',
  CheckIn2: 'Clock in 2',
  CheckOut2: 'Clock out 2',
  Reviewed: 'Reviewed',
  Priority: 'Priority',
  ReviewMark: 'Review',
  Low: 'Low',
  CheckIn3: 'Clock in 3',
  CheckOut3: 'Clock out 3',
  RestCheckIn1: 'Rest clock in 1',
  RestCheckOut1: 'Rest clock out 1',
  RestCheckIn2: 'Rest clock in 2',
  RestCheckOut2: 'Rest clock out 2',
  RestCheckIn3: 'Rest clock in 3',
  RestCheckOut3: 'Rest Clock out 3',
  WorkingDayDuration: 'Duration of working day',
  RestDuration: 'Rest duration',
  CustomizeJourney: 'Customize',
  Duration: 'Duration',
  NightHours: 'Night hours',
  DistanceTraveled: 'Distance traveled',
  RestDurationExceed: 'Rest exceed',
  EarlyHours: 'Early hours',
  RegularHours: 'Regular hours',
  SpecialDayHours: 'Special day hours',
  RestDayWorkedHours: 'Rest day worked hours',
  RestDay: 'Rest day',
  RestDays: 'Rest days',
  AddRestDays: 'Add rest days',
  SelectABranch: 'Select a branch',
  ShiftTemplates: 'Shift templates',
  dayoffId: 'Absence ID',
  NewTemplate: 'Create template',
  isSuspended: 'Is suspended?',
  FAQ: 'Frequently Asked Questions',
  Handbooks: 'Handbooks',
  Tutorials: 'Tutorials',
  ClickToWatchTooltip: 'Click to watch the video',
  SinceLastYear: 'since last year',
  Mobile: 'Mobile',
  Landline: 'Landline',
  Fax: 'Fax',
  Daily2: 'Daily',
  MinutesShort: 'min',
  Minutes: 'Minutes',
  Custom: 'Custom',
  ChangesWillBeLost: 'Any changes you have made will be lost.',
  Continue: 'Continue',
  DoYouWishToContinue: 'Do you wish to continue?',
  ManageAndCustomizeGeneralSettings: 'Manage and customize general settings',
  ServiceEnablement: 'Service enablement',
  AllowBreaks: 'Allow breaks',
  AllowClocking: 'Allow check-in',
  EnableAutomaticDayClosure: 'Enable automatic day closure',
  EnableAutomaticShiftGeneration: 'Enable automatic shift generation',
  TheShiftCalendarWillBeUpdated:
    'The shift calendar will be automatically updated for 8 weeks onwards.',
  Permissions: 'Permissions',
  RecognitionErrorRejected: 'The recognition error was rejected',
  RecognitionErrorApproved: 'The recognition error was approved',
  AllowOffLocationClockingIn: 'Allow clock-in without location ',
  AllowClockingInWithoutAnAssignedShift: 'Allow clock-in without shift',
  AllowClockingInInCaseOfSuspensions: 'Allow clock-in with a suspension',
  AllowClockingInWithApprovedAbsence: 'Allow clock-in with approved day-off ',
  ToleranceEarlyEntry: 'Early entry tolerance (in minutes)',
  Tolerance: 'Tolerance',
  ExampleUse: 'Example use',
  Developers: 'Developers',
  ApiEnabledFunctions: 'The API-enabled functions can be found at:',
  viewDocuments: 'View documents',
  apiExplanation:
    'The Fichap API uses API keys to authenticate requests.  You can view, update and copy your keys in the Dashboard. ',
  apiUnderlineMessage: 'When a new token is generated, the old token cannot be used.',
  journeyDetail: 'Workday detail',
  statusWorkday: 'Workday status',
  TheCollaboratorHasNoTemplates: 'The collaborator has no assigned templates',
  LateClockIn: 'Late clock in hours',
  _FileNumber: 'File number',
  earlyClockOut: 'Early Clock Out',
  Rest: 'Rest',
  nightExtraHours: 'Night extra hours',
  extraHours25: 'Extra hours 2.5',
  STARTED: 'Started',
  PAUSE: 'Pause',
  FINISH: 'Finish',
  AUTOMATIC_FINISH: 'Automatic Finish',
  CANCEL: 'Cancel',
  CHANGE: 'Change',
  ShifstWillBeLost: 'The shifts assigned to the employee will be lost',
  AVAILABLE: 'Available',
  NO_AVAILABLE: 'Not available',
  IN_MAINTENANCE: 'In maintenance',
  DATE_BEGINS: 'DateBegins',
  DATE_ENDS: 'DateEnds',
  CREATE_INVENTORY_TYPE: 'Create Inventory Type',
  CREATE: 'Create',
  EditInventoryType: 'Edit Inventory Type',
  SEARCH_ARTICLE: 'Search article',
  ARTICLE: 'Article',
  NEW_ARTICLE: 'New article',
  Issue: 'Issue',
  Assignation: 'Assignation',
  ASSIGNED: 'Assigned',
  EndAssignment: 'End assignment',
  AreYouSureAssignment: 'Are you sure you want to finish your assignment?',
  CreateAndAssign: 'Create and assign',
  CreateAndAssignArticle: 'Create and assign article',
  ItemDescription: 'Item description',
  ItemIssue: 'Assignment issue',
  History: 'History',
  ArticleUpdated: 'Article updated',
  AssignedArticles: 'Assigned articles',
  UNSUBSCRIBE: 'Unsubscribe',
  ASSIGN_ARTICLE: 'Assing article',
  EDIT_ARTICLE: 'Edit article',
  ArticleCreated: 'The article was successfully created',
  ArticleDeleted: 'The article was successfully deleted',
  MustBerNumber: 'Must be a Number',
  AdditionalIndications: 'Additional Indications',
  DescriptionOfTheBranch: 'Description of the branch',
  TimeTable: 'Timetable',
  ModifiedTimeTable: 'Modified Timetable',
  PreviusTimeTable: 'Previus Timetable',
  Generation: 'Generation',

  TradeMarkHistory: 'Trademark History',
  ActionType: 'Action Type',
  ClockIn: 'Start Of Workday',
  ClockOut: 'End Of Workday',
  StartBreak: 'Rest Start',
  EndBreak: 'End Of Rest',
  Offline: 'Offline',
  Manual: 'Manual',
  Automatic: 'Automatic',
  Billboard: 'Billboard',
  AdministratorWithMoreArticlesPosted: 'Administrator with more articles posted',
  PublishedArticles: 'Published articles',
  ArticlesRead: ' Articles read',
  MEDIUM: 'MEDIUM',
  Billboardactivity: 'Billboard activity',
  FichapUser: 'Fichap User',
  User: 'User',
  NewUser: 'New user',
  EditUser: 'Edit user',
  Users: 'Users',
  BranchesAllowed: 'branches allowed',
  MostReadCategory: 'Most read category',
  MostReadArticle: 'Most read Article',
  Author: 'Author',
  Publications: 'Publications',
  Categories: 'Categories',
  informationalBanner: 'informational banner',
  Posted: 'Posted',
  Drafts: 'Drafts',
  PaperBin: 'Paper bin',
  NewinformativeBanner: 'New informative banner',
  EditinformativeBanner: 'Edit informative banner',
  CoverImage: 'Cover image',
  Preview: 'Preview',
  BackgroundColor: 'Background color',
  Colors: 'Colors',
  SaveDraft: 'Save draft',
  PublicationDesc:
    'Your publications will go from draft to published status. Do you want to publish them?',
  deletePostDesc: 'Your publications will be permanently deleted Do you want to delete them?',
  Publish: 'Publish',
  disenrollPublicationDesc:
    'The publication will be unsubscribed and placed in the trash. Do you wish to unsubscribe your publication?',
  DestineURL: 'Destine URL',
  NewPost: 'New post',
  SavedAsDraft: 'Saved as draft',
  SaveAsDraft: 'Save as draft',
  Template: 'Template',
  TypeJourney: 'Type of journey',
  AssignedPeriod: 'Assigned period',
  CreateSimpleTemplate: 'Create simple template',
  CreateCustomTemplate: 'Create custom template',
  NewShiftTemplate: 'New shift template',
  SuccessfulPublication: 'Successful publication',
  weekday1: 'M',
  weekday2: 'T',
  weekday3: 'W',
  weekday4: 'T',
  weekday5: 'F',
  weekday6: 'S',
  weekday0: 'S',
  weekday3letters0: 'Sun',
  weekday3letters1: 'Mon',
  weekday3letters2: 'Tue',
  weekday3letters3: 'Wed',
  weekday3letters4: 'Thu',
  weekday3letters5: 'Fri',
  weekday3letters6: 'Sat',
  notImageError: 'image is required',
  IsRecurring: 'Repeat every year',
  IsDeductedFromVacation: 'Is deducted from vacations',
  allM: 'All', // siguiendo el patron del html entity &ordm; y &ordf;
  allF: 'All',
  'holiday.history.availableDays': 'Accumulated vacation',
  'holiday.history.tooltip':
    'Number of days added from Number of days added through cumulative bulk loading',
  typeOfSpecialDay: 'Types of special days',
  ValueMoney: 'Value Money ($)',
  Percentage: 'Percentage (%)',
  United: 'Units (0-9)',
}

let append = Arr.dot(fichapFormMessages, 'FichapForm')
messages = { ...messages, ...append }

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
